import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import qs from 'qs'

type Props = {
  learnerIds?: number[]
  moduleKeys: string[]
  durationInMinutes: number | ''
  start_date: string
  start_time: string
}

const FindCoachButton = ({ learnerIds, moduleKeys, durationInMinutes, start_date, start_time }: Props) => {
  const enabled =
    durationInMinutes && start_date && start_time && moduleKeys.length > 0 && learnerIds && learnerIds.length > 0

  const data = {
    coaching_session: {
      module_keys: moduleKeys,
      duration_in_minutes: durationInMinutes,
      start: `${start_date}T${start_time}`,
      learner_ids: learnerIds
    }
  }
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={`/replacement_coaches?${qs.stringify(data, { arrayFormat: 'brackets' })}`}
      className={`sw-btn btn-sm ml-4 ${enabled ? '' : 'disabled'}`}
      target="_blank"
      rel="opener"
    >
      <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
      Find Coach
    </a>
  )
}

export default FindCoachButton
