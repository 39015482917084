import { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { CalendarEvent, ClearCoachSelectionEvent, CoachSelectionEvent, CoachSelectionEventData } from './types'
import CalendarDisplay from './calendar_display'
import { apiRequest } from '../utils/json_request'
import { formatEvent } from './utils'
import Errors, { ModelErrors } from '../library/errors'
import { FlashAlert } from '../library/flash_message'
import EventDisplay from './event_display'
import { Timezone } from '../generated_types/coach_availability'
import Toolbar from './toolbar'
import { DateTime } from 'luxon'
import ShowAllDayButton from './show_all_day_button'
import SWCheckbox from '../library/sw/sw_checkbox'

type Props = {
  container: HTMLElement
  date: string
  timezone: Timezone
}
const CoachAvailabilityViewer = ({ container, date, timezone }: Props) => {
  const [showAllDay, setShowAllDay] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | undefined>()
  const [errors, setErrors] = useState<ModelErrors | undefined>()
  const [coachTimetableEvents, setCoachTimetableEvents] = useState<CalendarEvent[]>([])
  const [pendingRequests, setPendingRequests] = useState(0)
  const [userIds, setUserIds] = useState<number[]>([])
  const [typesToDisplay, setTypesToDisplay] = useState(['coach_availability', 'snooze', 'timeslot', 'training_session'])

  const loadEventsForUser = useCallback(
    async (userId: number) => {
      setPendingRequests(current => current + 1)
      const result = await apiRequest(`/users/${userId}/coach_availabilities`, {
        method: 'GET',
        payload: undefined,
        query: { date }
      })
      setPendingRequests(current => current - 1)
      if (result.ok) {
        setCoachTimetableEvents(current =>
          current.concat(result.data.coach_timetable_events.map(ev => formatEvent(ev, true)))
        )
        setErrors(undefined)
      } else {
        setErrors(result.errors)
      }
    },
    [date]
  )

  useEffect(() => {
    const handler = (e: CustomEvent<CoachSelectionEventData>) => {
      if (e.detail.checked) {
        setUserIds(current => {
          if (current.includes(e.detail.userId)) {
            return current
          }
          return current.concat(e.detail.userId)
        })
        loadEventsForUser(e.detail.userId)
      } else {
        setUserIds(current => current.filter(id => id !== e.detail.userId))
        setCoachTimetableEvents(current => current.filter(event => event.userId !== e.detail.userId))
      }
    }

    container.addEventListener(CoachSelectionEvent, handler)

    const clearSelectionHandler = (_e: CustomEvent) => {
      setUserIds([])
      setCoachTimetableEvents([])
    }

    container.addEventListener(ClearCoachSelectionEvent, clearSelectionHandler)

    return () => {
      container.removeEventListener(CoachSelectionEvent, handler)
      container.removeEventListener(ClearCoachSelectionEvent, clearSelectionHandler)
    }
  }, [container, loadEventsForUser])

  const handleDateChanged = useCallback(
    async (newDate: Date) => {
      let newEvents: CalendarEvent[] = []
      setPendingRequests(current => current + 1)
      setSelectedEvent(undefined)

      for (const userId of userIds) {
        const result = await apiRequest(`/users/${userId}/coach_availabilities`, {
          method: 'GET',
          payload: undefined,
          // we want the date in local time zone: if the current TZ has a positive UTC offset then when the user has selected 'monday 1st april'
          // then the utc date time will be march 31st at some time, so we would submit 2024-03-31T... which the backend would process as
          // 'please show the events for the week containing 2024-03-31', ie the previous week
          query: { date: DateTime.fromJSDate(newDate).toISODate()! }
        })

        if (result.ok) {
          newEvents = newEvents.concat(result.data.coach_timetable_events.map(ev => formatEvent(ev, true)))
        } else {
          setPendingRequests(current => current - 1)
          setErrors(result.errors)
          return
        }
      }

      setPendingRequests(current => current - 1)
      setErrors(undefined)
      setCoachTimetableEvents(newEvents)
    },
    [userIds]
  )

  const components = useMemo(
    () => ({
      event: EventDisplay,
      timeGutterHeader: () => <ShowAllDayButton showAllDay={showAllDay} setShowAllDay={setShowAllDay} />,
      toolbar: Toolbar({ timezone, editable: false })
    }),
    [timezone, showAllDay]
  )

  const filteredCoachTimetableEvents = useMemo(
    () => coachTimetableEvents.filter(c => typesToDisplay.includes(c.type)),
    [coachTimetableEvents, typesToDisplay]
  )

  const handleFilterCheckboxChanged: ChangeEventHandler<HTMLInputElement> = event => {
    const checkboxValue = event.currentTarget.value
    if (event.currentTarget.checked) {
      setTypesToDisplay(current => {
        if (current.includes(checkboxValue)) return current
        return current.concat(checkboxValue)
      })
    } else {
      setTypesToDisplay(current => current.filter(value => value !== checkboxValue))
    }
  }

  if (userIds.length > 0) {
    return (
      <>
        {errors && (
          <FlashAlert sticky onClose={() => setErrors(undefined)}>
            <ul className="list-inside">
              <Errors errors={errors} as="li" />
            </ul>
          </FlashAlert>
        )}

        <div className="sw-card">
          <div className="flex flex-wrap flex-row gap-2 items-center justify-start">
            <h4 className="my-0">Data to Include:</h4>
            <SWCheckbox
              label="Availability"
              value="coach_availability"
              classNames="mr-0"
              defaultChecked={true}
              onChange={handleFilterCheckboxChanged}
            />
            <SWCheckbox
              label="Timeslots"
              value="timeslot"
              classNames="mr-0"
              defaultChecked={true}
              onChange={handleFilterCheckboxChanged}
            />
            <SWCheckbox
              label="Sessions"
              value="training_session"
              classNames="mr-0"
              defaultChecked={true}
              onChange={handleFilterCheckboxChanged}
            />
            <SWCheckbox
              label="Pauses"
              value="snooze"
              classNames="mr-0"
              defaultChecked={true}
              onChange={handleFilterCheckboxChanged}
            />
          </div>
        </div>

        <CalendarDisplay
          className={'h-[75dvh] mb-8 mt-4'}
          loading={pendingRequests > 0}
          components={components}
          coachTimetableEvents={filteredCoachTimetableEvents}
          date={date}
          showAllDay={showAllDay}
          editable={false}
          timezone={timezone}
          canEditSessions={true} // for now this is always true - being able to view multiple coach availability, sessions etc. is an admin sort of task
          // and admins have the right to edit sessions. THis only controls whether we show the edit button so it wouldn't be catastrophic if
          // in the future we had users who were allowed to view availability data but not edit sessions
          setSelectedEvent={setSelectedEvent}
          selectedEvent={selectedEvent}
          showNames={true}
          sidebarEnabledTypes={['training_session', 'timeslot', 'coach_availability']}
          onNavigate={handleDateChanged}
        />
      </>
    )
  } else {
    return null
  }
}

export default CoachAvailabilityViewer
