import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import Modal from '../library/modal'
import { UserStub } from '../training_session_form/types'
import TrainerSelector from '../library/trainer_selector'
import { Form, Formik, FormikConfig } from 'formik'
import { useEffect, useState } from 'react'
import { apiRequest } from '../utils/json_request'
import { ModelErrors } from '../library/errors'
import ErrorsToast from '../library/errors_toast'
import SWFieldWithLabel from '../library/sw/sw_field_with_label'
import SWFormGroup from '../library/sw/sw_form_group'

type Props = {
  onClose: () => void
  coaches: UserStub[]
  coachId?: number
  initialMessage: string
}

type FormData = {
  content: string
  recipientId: number | ''
}

const CoachMessagingModal = ({ onClose, coaches, coachId: initialCoachId, initialMessage }: Props) => {
  const [coachChannelUrl, setCoachChannelUrl] = useState<string | undefined>()
  const [errors, setErrors] = useState<ModelErrors | undefined>()
  const [messageId, setMessageId] = useState<number | undefined>()

  const initialData = {
    content: initialMessage,
    recipientId: initialCoachId || ''
  } as const

  const onCoachSelected = async (id: number | undefined) => {
    if (!id) {
      setErrors(undefined)
      setCoachChannelUrl(undefined)
      return
    }
    const response = await apiRequest('/coach_slack_messages/new', {
      method: 'GET',
      query: { coach_slack_message: { recipient_id: id } },
      payload: undefined
    })

    if (response.ok) {
      setErrors(undefined)
      setCoachChannelUrl(response.data.channel_url)
    } else {
      setErrors(response.errors)
      setCoachChannelUrl(undefined)
    }
  }

  useEffect(() => {
    if (initialCoachId) {
      onCoachSelected(initialCoachId)
    }
    // we only want this to run when the modal opens
    // in practice this never changes anyway - you would have to close the modal for this to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit: FormikConfig<FormData>['onSubmit'] = async values => {
    if (!values.content || !values.recipientId) {
      return
    }
    setErrors(undefined)
    const response = await apiRequest('/coach_slack_messages', {
      method: 'POST',
      payload: {
        coach_slack_message: {
          recipient_id: values.recipientId,
          content: values.content
        }
      }
    })

    if (response.ok) {
      setMessageId(response.data.coach_slack_message.id)
      setErrors(undefined)
    } else {
      setErrors(response.errors)
    }
  }

  return (
    <Formik<FormData> onSubmit={onSubmit} initialValues={initialData}>
      {({ values, setFieldValue, isSubmitting }) => {
        if (messageId) {
          return (
            <Modal
              title="Send Message to Coach"
              options={{ active: true }}
              footer={
                <button onClick={onClose} className="sw-btn btn-primary">
                  <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                  Back
                </button>
              }
              onClose={onClose}
            >
              <div className="sw-toast success">
                Message Sent Successfuly
                <a
                  href={`/coach_slack_messages/${messageId}`}
                  target="_blank"
                  rel="noreferrer"
                  className="ml-2 sw-btn btn-sm"
                >
                  <FontAwesomeIcon icon={['fab', 'slack']} />
                  View In Slack
                </a>
              </div>
              <SWFormGroup>
                <label className="sw-label">Coach</label>
                <input
                  className="sw-input"
                  readOnly={true}
                  value={coaches.find(c => c.id === values.recipientId)?.name}
                  disabled={true}
                />
              </SWFormGroup>
              <SWFormGroup>
                <label className="sw-label">Message</label>
                <textarea className="sw-input min-h-48" value={values.content} disabled={true} />
              </SWFormGroup>
            </Modal>
          )
        }

        return (
          <Form>
            <Modal
              title="Send Message to Coach"
              options={{ active: true }}
              onClose={onClose}
              footer={
                <>
                  <button type="button" disabled={isSubmitting} onClick={onClose} className="sw-btn mr-auto">
                    <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                    Cancel
                  </button>
                  <button
                    disabled={isSubmitting || !(values.recipientId && values.content)}
                    type="submit"
                    className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                  >
                    Send
                    <FontAwesomeIcon icon={['far', 'paper-plane']} />
                  </button>
                </>
              }
            >
              {errors && <ErrorsToast errors={errors} />}
              <SWFormGroup>
                <TrainerSelector
                  label={'Coach'}
                  name="recipientId"
                  trainers={coaches}
                  initialTrainerID={initialCoachId || undefined}
                  onTrainerChanged={id => {
                    setFieldValue('recipientId', id)
                    onCoachSelected(id)
                  }}
                  disabled={isSubmitting}
                >
                  {coachChannelUrl && (
                    <a href={coachChannelUrl} target="_blank" className="sw-btn btn-sm" rel="noreferrer">
                      <FontAwesomeIcon icon={['fab', 'slack']} />
                      View Slack History
                    </a>
                  )}
                </TrainerSelector>
              </SWFormGroup>
              <SWFormGroup>
                <SWFieldWithLabel
                  as="textarea"
                  name="content"
                  label="Message"
                  className="sw-input min-h-48"
                  readOnly={isSubmitting}
                />
              </SWFormGroup>
            </Modal>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CoachMessagingModal
