import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import useScheduler from './use_scheduler'
import { MouseEventHandler } from 'react'
import qs from 'qs'
import { NewTrainingSessionPayload } from '../../../training_session_form/types'

type Props = {
  disabled: boolean
  payload: NewTrainingSessionPayload
  companyId: number
}

const FindACoachButton = ({ disabled, companyId, payload }: Props) => {
  const { selectedSessionDuration, selectedCells } = useScheduler()

  const openNewSessionWindow: MouseEventHandler = event => {
    event.stopPropagation()
    event.preventDefault()
    window.open(
      `/companies/${companyId}/coaching_sessions/new?` +
        qs.stringify({ training_session: payload }, { arrayFormat: 'brackets' })
    )
  }

  const submittable =
    selectedSessionDuration.start_date !== '' &&
    selectedSessionDuration.duration_in_minutes !== '' &&
    selectedSessionDuration.start_time !== '' &&
    selectedCells.modules.length >= 1 &&
    Object.keys(selectedCells.users).length >= 1

  return (
    <button disabled={!submittable || disabled} className="sw-btn basis-full" onClick={openNewSessionWindow}>
      Find Coach
      <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
    </button>
  )
}

export default FindACoachButton
