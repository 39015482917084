import { Learner } from '../types'
import { PlanCostData } from './plan_cost'
import { round } from 'lodash'

interface PlanCostProps {
  remainingPerLearner: PlanCostData
  learners: Learner[]
  editable: boolean
}

const PerLearnerCostDisplay = ({ remainingPerLearner, learners, editable }: PlanCostProps) => {
  return (
    <tr>
      <td className="controls">
        <span className="my-5 font-bold">Remaining Credits</span>
      </td>
      {editable && <td className="estimate-mins"></td>}
      {learners.map(learner => (
        <td key={learner.user_id}>
          {learner.user_id in remainingPerLearner && (
            <span
              className="sw-tooltip font-bold"
              data-tooltip={`${round(remainingPerLearner[learner.user_id].mandatory, 2)} credits (${round(
                remainingPerLearner[learner.user_id].includingOptional,
                2
              )} including optional)`}
            >
              {round(remainingPerLearner[learner.user_id].mandatory, 2)}
            </span>
          )}
        </td>
      ))}
    </tr>
  )
}

export default PerLearnerCostDisplay
