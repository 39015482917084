import { useCallback, useMemo, useState } from 'react'
import { CoachAvailabilityEditorProps } from '../generated_types/coach_availability'
import { EventInteractionArgs } from '@skiller-whale/react-big-calendar/lib/addons/dragAndDrop'
import { SlotInfo } from '@skiller-whale/react-big-calendar'

import { apiRequest } from '../utils/json_request'
import Errors, { ModelErrors } from '../library/errors'
import { FlashAlert } from '../library/flash_message'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import ShowAllDayButton from './show_all_day_button'
import { DateTime } from 'luxon'
import Toolbar from './toolbar'
import { UserCoachAvailabilitiesPath, ProfileCoachAvailabilitiesPath } from '../utils/api/paths'
import { CalendarEvent } from './types'
import { formatEvent } from './utils'
import EventDisplay from './event_display'
import CalendarDisplay from './calendar_display'

const CoachAvailabilityEditor = ({
  coach_timetable_events: initialEvents,
  date,
  user_id,
  timezones,
  can_edit_sessions,
  include_explanation,
  calendar_classes
}: CoachAvailabilityEditorProps) => {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | undefined>()

  const [editable, setEditable] = useState(false)
  const [timezone, setTimezone] = useState(timezones[0])

  const [loading, setLoading] = useState(false)
  const [showAllDay, setShowAllDay] = useState(false)
  const [coachTimetableEvents, setCoachTimetableEvents] = useState<CalendarEvent[]>(() =>
    initialEvents.map(event => formatEvent(event, false))
  )

  const [errors, setErrors] = useState<ModelErrors | undefined>()

  const setEventLoading = useCallback((id: number, value: boolean) => {
    setCoachTimetableEvents(current =>
      current.map(event =>
        event.type === 'coach_availability' && event.id === id ? { ...event, loading: value } : event
      )
    )
  }, [])

  const handleSelectSlot = useCallback(
    async (slot: SlotInfo) => {
      if (!editable) {
        return
      }

      const placeholder = {
        type: 'placeholder',
        loading: true,
        start: slot.start,
        end: slot.end,
        userId: 0,
        userName: ''
      } as const

      setCoachTimetableEvents(existing => existing.concat(placeholder))

      const url: UserCoachAvailabilitiesPath | typeof ProfileCoachAvailabilitiesPath = user_id
        ? `/users/${user_id}/coach_availabilities`
        : '/profile/coach_availabilities'

      const result = await apiRequest(url, {
        method: 'POST',
        payload: {
          coach_availability: {
            projected_start: slot.start.toISOString(),
            projected_finish: slot.end.toISOString()
          }
        }
      })

      if (result.ok) {
        setErrors(undefined)
        setCoachTimetableEvents(existing =>
          existing
            .concat(result.data.coach_timetable_events.map(event => formatEvent(event, false)))
            .filter(event => event !== placeholder)
        )
      } else {
        setErrors(result.errors)
        setCoachTimetableEvents(existing => existing.filter(event => event !== placeholder))
      }
    },
    [editable, user_id]
  )

  const handleEventUpdate = useCallback(
    async ({ event, start, end }: EventInteractionArgs<CalendarEvent>) => {
      if (event.type !== 'coach_availability') return
      setEventLoading(event.id, true)
      const result = await apiRequest(`/coach_availabilities/${event.id}`, {
        method: 'PATCH',
        payload: {
          coach_availability: {
            projected_start: toISOString(start),
            projected_finish: toISOString(end)
          }
        }
      })
      if (result.ok) {
        setCoachTimetableEvents(existing => {
          const without = existing.filter(
            // remove the edited events
            e => !(e.type === 'coach_availability' && e.id === event.id)
          )
          return without.concat(result.data.coach_timetable_events.map(event => formatEvent(event, false)))
        })
        setErrors(undefined)
      } else {
        setEventLoading(event.id, false)
        setErrors(result.errors)
      }
    },
    [setEventLoading]
  )

  const handleEventDelete = useCallback(
    async event => {
      if (event.type !== 'coach_availability') return
      setEventLoading(event.id, true)
      const result = await apiRequest(`/coach_availabilities/${event.id}`, {
        method: 'DELETE',
        payload: undefined
      })
      if (result.ok) {
        setCoachTimetableEvents(existing =>
          existing.filter(e => !(e.type === 'coach_availability' && e.id === event.id))
        )
        setErrors(undefined)
      } else {
        setEventLoading(event.id, false)
        setErrors(result.errors)
      }
    },
    [setEventLoading]
  )

  const components = useMemo(
    () => ({
      event: editable ? withDelete({ onDelete: handleEventDelete }) : EventDisplay,
      timeGutterHeader: () => <ShowAllDayButton showAllDay={showAllDay} setShowAllDay={setShowAllDay} />,
      toolbar: Toolbar({ timezones, setTimezone, timezone, setEditable, editable })
    }),
    [handleEventDelete, editable, showAllDay, timezone, timezones, setTimezone]
  )

  const draggable = useCallback((event: CalendarEvent) => editable && event.type === 'coach_availability', [editable])

  const handleDateChanged = useCallback(
    async (newDate: Date) => {
      const url: UserCoachAvailabilitiesPath | typeof ProfileCoachAvailabilitiesPath = user_id
        ? `/users/${user_id}/coach_availabilities`
        : '/profile/coach_availabilities'
      setLoading(true)
      setSelectedEvent(undefined)
      const result = await apiRequest(url, {
        method: 'GET',
        payload: undefined,
        // we want the date in local time zone: if the current TZ has a positive UTC offset then when the user has selected 'monday 1st april'
        // then the utc date time will be march 31st at some time, so we would submit 2024-03-31T... which the backend would process as
        // 'please show the events for the week containing 2024-03-31', ie the previous week
        query: { date: DateTime.fromJSDate(newDate).toISODate()! }
      })
      setLoading(false)
      if (result.ok) {
        setCoachTimetableEvents(result.data.coach_timetable_events.map(ev => formatEvent(ev, false)))
        setErrors(undefined)
      } else {
        setErrors(result.errors)
      }
    },
    [user_id]
  )

  return (
    <>
      {errors && (
        <FlashAlert sticky onClose={() => setErrors(undefined)}>
          <ul className="list-inside">
            <Errors errors={errors} as="li" />
          </ul>
        </FlashAlert>
      )}

      <h3>{editable ? 'Availability for Coaching' : 'Schedule'}</h3>

      {include_explanation && (
        <>
          <p>
            To edit your availability, click the pencil icon then click and drag over the times you&apos;ll typically be
            available for coaching.
          </p>
          <ul className="list-inside">
            <li>
              This should be indicative, we&apos;ll still always check with you before starting a new group, or moving a
              group to a new regular time slot.
            </li>
            <li>
              We may still ask you about other times you haven’t selected here, but you&apos;re more likely to be asked
              about times you&apos;ve marked as available.
            </li>
          </ul>
        </>
      )}

      <CalendarDisplay
        className={calendar_classes}
        loading={loading}
        components={components}
        coachTimetableEvents={coachTimetableEvents}
        date={date}
        showAllDay={showAllDay}
        editable={editable}
        timezone={timezone}
        onSelectSlot={handleSelectSlot}
        onEventResize={handleEventUpdate}
        onEventDrop={handleEventUpdate}
        resizableAccessor={draggable}
        draggableAccessor={draggable}
        dayLayoutAlgorithm={'no-overlap'}
        onNavigate={handleDateChanged}
        canEditSessions={can_edit_sessions}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        showNames={false}
        sidebarEnabledTypes={['training_session']}
      />
    </>
  )
}

// the types says that EventInteractionArgs is stringOrDate which is a bit unhelpful
const toISOString = (arg: string | Date) => {
  if (typeof arg === 'string') {
    return arg
  } else return arg.toISOString()
}

type EventWithDeleteButtonProps = {
  onDelete: (event: CalendarEvent) => void
}
// returns a component that will use the supplied onDelete
const withDelete = ({ onDelete }: EventWithDeleteButtonProps) => {
  const result = ({ event }: { event: CalendarEvent }) => {
    return (
      <>
        <button
          aria-label="Delete"
          className="right-0 top-0 text-sm absolute hover:bg-white rounded p-0.5"
          onClick={e => {
            e.preventDefault()
            onDelete(event)
          }}
        >
          <FontAwesomeIcon icon={['far', 'trash-can']} />
        </button>
        <EventDisplay event={event} />
      </>
    )
  }
  result.displayName = 'EventWithDeleteButton'
  return result
}

export default CoachAvailabilityEditor
