export type CalendarEvent = {
  start: Date
  end: Date
  isAllDay?: boolean
  title?: string
  loading?: boolean
  userId: number
  userName: string
} & (
  | {
      type: 'coach_availability'
      id: number
    }
  | {
      type: 'snooze'
      id: number
    }
  | {
      type: 'timeslot'
      id: number
      plan_id: number
      company_name: string
    }
  | {
      type: 'training_session'
      code: string
      number_of_learners: number
      company_names: string[] | null
      module_titles: string[]
      session_url: string
    }
  | {
      type: 'placeholder'
    }
)

export const CoachSelectionEvent = 'skw-coach-selected'
export type CoachSelectionEventData = {
  userId: number
  checked: boolean
}

export const ClearCoachSelectionEvent = 'skw-clear-coach-selection'
