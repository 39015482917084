import withDragAndDrop, { EventInteractionArgs } from '@skiller-whale/react-big-calendar/lib/addons/dragAndDrop'
import { CalendarEvent } from './types'
import { Calendar, CalendarProps, luxonLocalizer, Views } from '@skiller-whale/react-big-calendar'
import { ENABLED_VIEWS, eventClassNames, FORMATS } from './utils'
import '@skiller-whale/react-big-calendar/lib/css/react-big-calendar.css'
import '@skiller-whale/react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { useCallback, useMemo } from 'react'
import { DateTime, Settings } from 'luxon'
import { CoachTimetableEventData, Timezone } from '../generated_types/coach_availability'
import SidebarContent from './sidebar_content'

const DnDCalendar = withDragAndDrop(Calendar<CalendarEvent>)

type Props = {
  className?: string
  loading: boolean
  editable: boolean
  timezone: Timezone
  date: string
  showAllDay: boolean
  canEditSessions: boolean
  showNames: boolean
  coachTimetableEvents: CalendarEvent[]
  onEventResize?: (event: EventInteractionArgs<CalendarEvent>) => void
  onEventDrop?: (event: EventInteractionArgs<CalendarEvent>) => void
  resizableAccessor?: (event: CalendarEvent) => boolean | keyof CalendarEvent
  draggableAccessor?: (event: CalendarEvent) => boolean | keyof CalendarEvent
  selectedEvent?: CalendarEvent
  setSelectedEvent: (e?: CalendarEvent) => void
  sidebarEnabledTypes: CoachTimetableEventData['type'][]
} & Omit<
  CalendarProps<CalendarEvent>,
  | 'selectable'
  | 'events'
  | 'backgroundEvents'
  | 'min'
  | 'max'
  | 'defaultDate'
  | 'localizer'
  | 'onSelectEvent'
  | 'eventPropGetter'
>

const CalendarDisplay = ({
  editable,
  components,
  timezone,
  date,
  onSelectSlot,
  onEventResize,
  onEventDrop,
  resizableAccessor,
  draggableAccessor,
  dayLayoutAlgorithm,
  onNavigate,
  coachTimetableEvents,
  defaultView = Views.WORK_WEEK,
  views = ENABLED_VIEWS,
  formats = FORMATS,
  showAllDay,
  canEditSessions,
  selectedEvent,
  setSelectedEvent,
  className,
  loading,
  showNames,
  sidebarEnabledTypes
}: Props) => {
  const { events, backgroundEvents } = useMemo(() => {
    const events = coachTimetableEvents.filter(
      ev => (editable && ev.type === 'coach_availability') || (!editable && ev.type !== 'coach_availability')
    )
    const backgroundEvents = coachTimetableEvents.filter(ev => !editable && ev.type === 'coach_availability')
    return { events, backgroundEvents }
  }, [coachTimetableEvents, editable])

  const { defaultDate, min, max, localizer } = useMemo(() => {
    Settings.defaultZone = timezone.value
    const defaultDate = DateTime.fromISO(date)
    return {
      defaultDate: defaultDate.toJSDate(),
      min: defaultDate.set({ hour: 6 }).toJSDate(),
      max: defaultDate.set({ hour: 22 }).toJSDate(),
      localizer: luxonLocalizer(DateTime)
    }
  }, [timezone, date])

  const eventPropGetter = useCallback((event: CalendarEvent) => eventClassNames(event, editable), [editable])

  return (
    <div className={`relative ${className}`}>
      {loading && (
        <div className="absolute w-full h-full sw-loading loading-lg pointer-events-auto opacity-50 transition-opacity z-10 bg-white"></div>
      )}
      <div className="absolute flex w-full gap-2 bottom-0 top-0">
        <div className="grow">
          <DnDCalendar
            components={components}
            events={events}
            backgroundEvents={backgroundEvents}
            min={showAllDay ? undefined : min}
            max={showAllDay ? undefined : max}
            defaultView={defaultView}
            views={views}
            defaultDate={defaultDate}
            localizer={localizer}
            formats={formats}
            selectable={editable}
            onSelectSlot={onSelectSlot}
            onEventResize={onEventResize}
            onEventDrop={onEventDrop}
            resizableAccessor={resizableAccessor}
            draggableAccessor={draggableAccessor}
            eventPropGetter={eventPropGetter}
            dayLayoutAlgorithm={dayLayoutAlgorithm}
            onNavigate={onNavigate}
            autoscrollWhenDragging={false}
            onSelectEvent={setSelectedEvent}
          />
        </div>
        {/* typescript doesn't understand that the event can't be a placeholder from sidebarEnabledTypes */}
        {selectedEvent && selectedEvent.type !== 'placeholder' && sidebarEnabledTypes.includes(selectedEvent.type) && (
          <div className="basis-72 sw-card py-2">
            <SidebarContent
              event={selectedEvent}
              onClose={() => setSelectedEvent(undefined)}
              canEditSessions={canEditSessions}
              showNames={showNames}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CalendarDisplay
