import { Controller } from '@hotwired/stimulus'

export default class DebouncedFormController extends Controller<HTMLFormElement> {
  static values = {
    interval: { type: Number, default: 250 }
  }

  declare intervalValue: number

  timeout?: number

  submit() {
    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(() => {
      this.element.requestSubmit()
    }, this.intervalValue)
  }

  submitNow() {
    window.clearTimeout(this.timeout)
    this.element.requestSubmit()
  }
}
