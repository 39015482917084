import qs from 'qs'
import { Learner } from '../types'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { MovesNewTrainingPlanUsersPath } from '../../utils/api/paths'

type Props = {
  hasChanges: boolean
  learners: Learner[]
  selectedUserIds: Set<number>
}

const MoveUsersLink = ({ hasChanges, learners, selectedUserIds }: Props) => {
  const trainingPlanUserIds = learners
    .filter(l => selectedUserIds.has(l.user_id))
    .map(l => l.id)
    .filter(id => id !== null) // unsaved learners can't be moved. slightly moot because in this case hasChanges would be true and we won't generate the link

  return (
    <a
      aria-disabled={hasChanges}
      className={`sw-btn ${hasChanges ? 'disabled' : ''}`}
      href={hasChanges ? '' : buildMoveTrainingPlanUsersHref(trainingPlanUserIds)}
    >
      <FontAwesomeIcon icon={['far', 'share-from-square']} />
      Move {selectedUserIds.size} user{selectedUserIds.size > 1 && 's'}
    </a>
  )
}

const buildMoveTrainingPlanUsersHref = (trainingPlanUserIds: number[]) => {
  return (
    MovesNewTrainingPlanUsersPath +
    '?' +
    qs.stringify({ training_plan_user_ids: trainingPlanUserIds }, { arrayFormat: 'brackets' })
  )
}

export { buildMoveTrainingPlanUsersHref }
export default MoveUsersLink
