import {
  ModuleAttendances,
  Modules,
  ModuleWithKey,
  PlanDependencies,
  Unavailability,
  UnavailabilityType,
  SelectedPlanCells,
  SessionDuration,
  TrainingSessionState
} from '../../types'
import { isSessionAvailable, isSessionNeeded } from '../session_editor_helpers'
import { getModulesFromKeys } from '../../utils'
import { LearnerIssues, LearnerIssue } from './scheduler_panel.types'
import { NewTrainingSessionPayload, SessionType } from '../../../training_session_form/types'
import { apiRequest } from '../../../utils/json_request'
import { ModelErrors } from '../../../library/errors'
import { openInNewTab } from '../../../utils/routing_helpers'
import getDisplaySession from '../session_status'
import { EditTrainingSessionPath } from '../../../utils/api/paths'
import { Sessions, SharedNotes } from '../../../generated_types/training_plan'

interface GetLearnerIssuesParams {
  userId: number
  selectedModules: ModuleWithKey[]
  allModules: Modules
  dependencies: PlanDependencies
  attendances: ModuleAttendances
  sharedNotes: SharedNotes
  sessions: Sessions
}

/**
 * For any of the selected modules, what issues stop the learner from taking part?
 * i.e. Module dependencies not met, module already completed, marked as not needed, etc.
 */
export const getLearnerIssues = ({
  userId,
  selectedModules,
  allModules,
  dependencies,
  attendances,
  sharedNotes,
  sessions
}: GetLearnerIssuesParams) => {
  const moduleStatuses: Required<LearnerIssues> = {
    [LearnerIssue.HasDependencies]: [],
    [LearnerIssue.ModuleUnneeded]: [],
    [LearnerIssue.ModuleUnconfirmed]: [],
    [LearnerIssue.ModuleCompleted]: [],
    [LearnerIssue.ModuleScheduled]: []
  }
  const selectedModuleKeys = selectedModules.map(module => module.key)

  selectedModules.forEach(module => {
    // Dependencies
    const dependencyKeys =
      dependencies[module.key] && dependencies[module.key][userId] ? dependencies[module.key][userId] : []
    const dependencyModules = getModulesFromKeys(dependencyKeys, allModules)
    dependencyModules.forEach(depModule => {
      const isDepAhead = isScheduledAhead(module.key, depModule.key, selectedModuleKeys)
      if (!isDepAhead) moduleStatuses[LearnerIssue.HasDependencies].push(depModule)
    })

    // Attendance
    const plannedSession = attendances[module.key][userId]
    const sessionStatuses = sessions[module.key]?.[userId]
    const displaySession = getDisplaySession(sessionStatuses)

    if (!isSessionNeeded(plannedSession, sharedNotes[module.key]?.[userId]))
      moduleStatuses[LearnerIssue.ModuleUnneeded].push(module)
    else if (displaySession?.status === TrainingSessionState.Unconfirmed)
      moduleStatuses[LearnerIssue.ModuleUnconfirmed].push(module)
    else if (displaySession?.status === TrainingSessionState.Complete)
      moduleStatuses[LearnerIssue.ModuleCompleted].push(module)
    else if (!isSessionAvailable(sessionStatuses)) moduleStatuses[LearnerIssue.ModuleScheduled].push(module)
  })

  // Filter statuses with empty arrays
  const issues: LearnerIssues = Object.fromEntries(
    Object.entries(moduleStatuses).filter(([_status, modules]) => modules.length > 0)
  )

  return Object.keys(issues).length > 0 ? issues : undefined
}

/**
 * Determines if "module" is scheduled after "other module" in a given module keys array
 */
export function isScheduledAhead(moduleKey: string, otherModuleKey: string, moduleKeys: string[]) {
  const moduleIndex = moduleKeys.indexOf(moduleKey)
  const otherModuleIndex = moduleKeys.indexOf(otherModuleKey)
  return moduleIndex >= 0 && otherModuleIndex >= 0 && otherModuleIndex < moduleIndex
}

/**
 * Determines the learner issue tooltip text based on LearnerIssue type
 */
export const getIssueTooltip = (issue: string, count: number) => {
  switch (issue) {
    case LearnerIssue.HasDependencies:
      return `Outstanding dependencies - ${count}`
    case LearnerIssue.ModuleUnneeded:
      return `Modules not needed (grey) - ${count}`
    case LearnerIssue.ModuleUnconfirmed:
      return `Modules unknown completion - ${count}`
    case LearnerIssue.ModuleCompleted:
      return `Modules already completed - ${count}`
    case LearnerIssue.ModuleScheduled:
      return `Modules already scheduled - ${count}`
    default:
      return ''
  }
}

/**
 * Splits an Unavailability[] in to those that conflict with other sessions (which block creating a new session) and others
 */
export const splitUnavailabilities = (unavailabilities?: Unavailability[]) => {
  const sessionUnavailabilities: Unavailability[] = []
  const otherUnavailabilities: Unavailability[] = []

  if (unavailabilities) {
    unavailabilities.forEach(unavailability => {
      if (unavailability.type === UnavailabilityType.Session) sessionUnavailabilities.push(unavailability)
      else otherUnavailabilities.push(unavailability)
    })
  }

  return {
    sessionUnavailabilities,
    otherUnavailabilities
  }
}

interface BuildNewSessionPayloadParams {
  selectedTrainerId: number | ''
  selectedCells: SelectedPlanCells
  selectedSessionDuration: SessionDuration
  permitAvailabilityClash: boolean
  permitChangeRequestClash: boolean
  catchup: boolean
  autoStartHostedEnvironments: boolean
}

export const buildNewSessionPayload = ({
  selectedTrainerId,
  selectedCells,
  selectedSessionDuration,
  permitAvailabilityClash,
  permitChangeRequestClash,
  catchup,
  autoStartHostedEnvironments
}: BuildNewSessionPayloadParams): NewTrainingSessionPayload => {
  const { duration_in_minutes, start_date, start_time } = selectedSessionDuration
  const modules = selectedCells.modules.map(module => module.key)

  const user_ids = Object.values(selectedCells.users).map(user => user.id)

  return {
    session_type: getSessionType(selectedCells.modules),
    duration_in_minutes: +duration_in_minutes,
    start_date,
    start_time,
    modules,
    user_ids,
    trainer_id: selectedTrainerId,
    permit_unpublished: true,
    permit_availability_clash: permitAvailabilityClash,
    permit_change_request_clash: permitChangeRequestClash,
    required_modules_for_followup: [],
    auto_start_hosted_environments: autoStartHostedEnvironments,
    catchup
  }
}

interface SubmitNewSessionParams {
  payload: NewTrainingSessionPayload
  companyId: number
}

/**
 * Submits new session to training_sessions api, redirecting to sessions list if successful.
 * Returns errors otherwise.
 */
export const submitNewSession = async ({
  payload,
  companyId
}: SubmitNewSessionParams): Promise<ModelErrors | undefined> => {
  const response = await apiRequest(`/companies/${companyId}/coaching_sessions`, {
    method: 'POST',
    payload: { training_session: payload }
  })
  const { ok, data } = response

  if (ok) {
    if ('edit_session_url' in data) openInNewTab(data.edit_session_url)
    else
      return {
        no_location: ['Edit session url not present in return']
      }
  } else {
    return response.errors
  }
}

export const getSessionType = (modules: ModuleWithKey[]): SessionType => {
  return modules.find(module => module.default_session_type)?.default_session_type || SessionType.Normal
}

type SubmitNewLearnersParams = {
  sessionCode: string
  userIds: number[]
  permitAvailabilityClash: boolean
  permitChangeRequestClash: boolean
}
/**
 * Adds additional learners to a training session
 */

export const submitNewLearners = async ({
  sessionCode,
  userIds,
  permitAvailabilityClash,
  permitChangeRequestClash
}: SubmitNewLearnersParams) => {
  const response = await apiRequest(`/coaching_sessions/${sessionCode}/attendances`, {
    method: 'POST',
    payload: {
      user_ids: userIds,
      permit_availability_clash: permitAvailabilityClash,
      permit_change_request_clash: permitChangeRequestClash
    }
  })

  const { ok } = response

  if (ok) {
    openInNewTab(`/coaching_sessions/${sessionCode}/edit` as EditTrainingSessionPath)
  } else {
    return response.errors
  }
}

type NewLearnersParams = {
  selectedCells: SelectedPlanCells
  existingCells?: SelectedPlanCells
}
/**
 * returns the userIds that are in selectedCells but not existingCells.
 * In "add to session" mode, selectedCells contains the userIds already in the session as well as the new
 * users. It's an error to add a user to a session they are already in, so we need to remove
 * these users before submitting
 */
export const newLearners = ({ selectedCells, existingCells }: NewLearnersParams): number[] => {
  const existingUserIds = existingCells ? Object.keys(existingCells.users) : []
  const allUserIds = Object.keys(selectedCells.users)

  const newIds = allUserIds.filter(id => !existingUserIds.includes(id))

  return newIds.map(id => parseInt(id, 10))
}
