import React, { MouseEventHandler, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { useDrag } from 'react-dnd'

import useReorderHoverDrop, { DIRECTION_HORIZONTAL } from '../components/use_reorder_hover_drop'
import { Learner, User } from '../types'
import TableMenu from './table_menu'
import { buildMoveTrainingPlanUsersHref } from './move_users_link'

const USER_ITEM_TYPE = 'user'

interface HeaderCellProps {
  user: User
  learner: Learner
  className?: string
  index: number
  editable: boolean
  markedForDestruction: boolean
  selected: boolean
  planHasChanges: boolean
  updateOrdering: (fromIndex: number, toIndex: number) => void
  toggleUserSelected: (userId: number) => void
  toggleUserDeleted: (userId: number) => void
  userMoved: boolean
  userIndefinitelyPaused: boolean
}

const HeaderCell = React.memo(
  ({
    user,
    learner,
    className,
    index,
    updateOrdering,
    editable,
    markedForDestruction,
    selected,
    toggleUserSelected,
    toggleUserDeleted,
    planHasChanges,
    userMoved,
    userIndefinitelyPaused
  }: HeaderCellProps) => {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)
    const [, drop] = useReorderHoverDrop({
      itemType: USER_ITEM_TYPE,
      direction: DIRECTION_HORIZONTAL,
      hoverIndex: index,
      updateOrdering,
      dropRef: dropRef
    })

    const [{ isDragging }, drag, preview] = useDrag({
      type: USER_ITEM_TYPE,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    })

    const opacity = isDragging ? 0 : 1

    if (editable) {
      drag(dragRef)
      preview(drop(dropRef))
    }

    const classNames = [className, 'h-full']
    const dragHandleClassNames = ['cursor-move rounded-md']
    let editControls = <></>

    if (userIndefinitelyPaused) classNames.push('indefinitely-paused')

    if (editable) {
      if (markedForDestruction) classNames.push('marked-for-destruction')
      dragHandleClassNames.push(
        userMoved ? 'border-2 border-error-border bg-error-bg text-error-text' : 'bg-rockgray-50'
      )

      const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault()
        e.currentTarget.blur()
        markedForDestruction ? toggleUserDeleted(user.id) : toggleUserSelected(user.id)
      }

      editControls = (
        <button onClick={handleClick} className={`sw-btn btn-icon w-9 h-9 mx-auto ${selected ? 'btn-active' : ''}`}>
          <FontAwesomeIcon icon={['fas', markedForDestruction ? 'rotate-left' : 'check']} />
        </button>
      )
    } else {
      dragHandleClassNames.push('hidden')
    }

    const copyEmailAddress = () => navigator.clipboard.writeText(user.email)
    const [menuProps, toggleMenu] = useMenuState()
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
    const showContextMenu: MouseEventHandler = e => {
      e.preventDefault()
      setAnchorPoint({ x: e.clientX, y: e.clientY })
      toggleMenu(true)
    }

    const menu = (
      <ControlledMenu
        {...menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
        menuClassName="font-semibold"
      >
        <MenuItem onClick={copyEmailAddress}>Copy Email Address</MenuItem>
        {learner.assessment_id && (
          <MenuItem target="blank" href={`/assessments/${learner.assessment_id}/redirect_results/${user.id}`}>
            View Assessment Results
          </MenuItem>
        )}
        <MenuItem target="blank" href={`/users/${user.id}`}>
          View User
        </MenuItem>
        <MenuItem target="blank" href={`/users/${user.id}/planned_sessions`}>
          View Planned Sessions
        </MenuItem>
        {
          // if learner.id is null this user hasn't been saved yet & can't be moved (because they're not really
          // in the plan yet )
          // we don't allow moving learners if the plan has unsaved changes - those unsaved changes would not be part of the move
          learner.id && !planHasChanges && editable && (
            <MenuItem href={buildMoveTrainingPlanUsersHref([learner.id])}>Move to another plan</MenuItem>
          )
        }
      </ControlledMenu>
    )

    return (
      <th ref={dropRef} style={{ opacity }} className={classNames.join(' ')} key={user.id}>
        <div className="flex flex-col justify-between gap-1 h-full">
          <div ref={dragRef} className={dragHandleClassNames.join(' ')}>
            <FontAwesomeIcon icon={['fas', 'grip']} size="xl" className="text-midgrey" />
          </div>
          {userIndefinitelyPaused && (
            <div className="sw-tooltip tooltip-bottom" data-tooltip={`Indefinitely\nPaused`}>
              <FontAwesomeIcon icon={['fas', 'clock']} size="xl" className="text-error-border" />
            </div>
          )}
          <div className="username" onContextMenu={showContextMenu}>
            {user.name}
          </div>
          {editControls}
        </div>
        <TableMenu>{menu}</TableMenu>
      </th>
    )
  }
)

HeaderCell.displayName = 'HeaderCell'

export default HeaderCell
