import React, { Dispatch, MouseEventHandler, SetStateAction, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { buildNewSessionPayload, newLearners, submitNewLearners, submitNewSession } from './scheduler_panel_helpers'
import TrainerPicker from './trainer_picker'
import ModuleList from './module_list'
import LearnerList from './learner_list'

import { ModuleAttendances, Modules, PlanDependencies } from '../../types'
import Errors, { ModelErrors } from '../../../library/errors'
import TimePeriodSelector from './time_period_selector'
import ConfirmationModal from '../../../library/confirmation_modal'
import { extractSessionConfirmationErrors } from '../../../training_session_form/utils'
import { ConfirmationErrors } from '../../../training_session_form/types'
import SWCheckbox from '../../../library/sw/sw_checkbox'
import useScheduler from './use_scheduler'
import { buildViewInOtherPlanUrl } from '../view_in_other_plan'
import { Sessions, SharedNotes } from '../../../generated_types/training_plan'
import FindACoachButton from './find_a_coach_button'

export interface SchedulerPanelProps {
  modules: Modules
  dependencies: PlanDependencies
  attendances: ModuleAttendances
  companyId: number
  setCellsSelectable: Dispatch<SetStateAction<boolean>>
  autoStartHostedEnvironments: boolean
  showFindLearners?: {
    planId: number
  }
  sharedNotes: SharedNotes
  sessions: Sessions
}

const SchedulerPanel = (props: SchedulerPanelProps) => {
  const { companyId, setCellsSelectable, autoStartHostedEnvironments, showFindLearners } = props

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState<ModelErrors | undefined>(undefined)
  const [clashErrors, setClashErrors] = useState<ConfirmationErrors | undefined>(undefined)
  const [trainerBlocked, setTrainerBlocked] = useState(false)
  const [minimised, setMinimised] = useState(false)
  const [catchup, setCatchup] = useState(false)

  const {
    learnerUnavailabilities,
    trainerUnavailabilities,
    onSchedulerDateChange,
    onSchedulerDateClear,
    selectedSessionDuration,
    selectedTrainerId,
    setSelectedTrainerId,
    selectedCells,
    setSelectedCells,
    uneditableCells,
    assignedTrainers,
    timeslotsByCoach,
    sessionCode
  } = useScheduler()

  const timeslots = selectedTrainerId >= 0 ? timeslotsByCoach[selectedTrainerId] || [] : undefined
  const changeTrainerState = (trainerId: number, trainerBlocked: boolean) => {
    setSelectedTrainerId(trainerId)
    setTrainerBlocked(trainerBlocked)
  }

  const onClear = () => {
    setSelectedCells({ modules: [], users: {} })
    setErrors(undefined)
    setCatchup(false)
    if (!sessionCode) {
      setSelectedTrainerId(-1)
      setTrainerBlocked(false)
      onSchedulerDateClear()
    }
  }

  /**
   * Reloads the page
   */

  const onSubmit = async ({ permitChangeRequestClash = false, permitAvailabilityClash = false }) => {
    if (selectedSessionDuration) {
      setClashErrors(undefined)
      setIsSubmitting(true)
      setCellsSelectable(false)

      const errors = sessionCode
        ? await submitNewLearners({
            sessionCode,
            userIds: newLearners({ selectedCells, existingCells: uneditableCells }),
            permitChangeRequestClash,
            permitAvailabilityClash
          })
        : await submitNewSession({
            payload: buildNewSessionPayload({
              selectedTrainerId,
              selectedCells,
              selectedSessionDuration,
              permitChangeRequestClash,
              permitAvailabilityClash,
              catchup,
              autoStartHostedEnvironments
            }),
            companyId
          })

      if (errors) {
        const { nonConfirmationErrors, confirmationErrors } = extractSessionConfirmationErrors(errors)

        if (confirmationErrors.all.length > 0) {
          setClashErrors(confirmationErrors)
          setErrors(nonConfirmationErrors)
        } else {
          setErrors(errors)
          setCellsSelectable(true)
        }

        setIsSubmitting(false)
      } else {
        setCellsSelectable(true)
        setIsSubmitting(false)
        setClashErrors(undefined)
        onClear()
      }
    }
  }

  const onMinimise = () => setMinimised(current => !current)

  useEffect(() => setMinimised(false), [selectedSessionDuration]) // Open the panel if a timeslot is chosen in scheduling

  const totalEstimatedDuration = selectedCells.modules.reduce(
    (aggregateMins, current) => aggregateMins + (current.estimated_minutes ?? 60),
    0
  )
  const editingSession = !!sessionCode
  const disableControls = isSubmitting || !!clashErrors
  const moduleBlocked = selectedCells.modules.some(module => module.exists !== true)
  const noCellsSelected = selectedCells.modules.length < 1 && Object.keys(selectedCells.users).length < 1
  const isUnSubmittable =
    noCellsSelected ||
    disableControls ||
    selectedTrainerId < 0 ||
    trainerBlocked ||
    moduleBlocked ||
    selectedSessionDuration.start_date === '' ||
    selectedSessionDuration.start_time === '' ||
    selectedSessionDuration.duration_in_minutes === '' ||
    selectedSessionDuration.duration_in_minutes === '0'

  const minimiseButtonText = minimised ? 'Restore Scheduler' : 'Minimise Scheduler'
  const headerContent = (
    <div className="flex justify-between items-center">
      <h4 className="m-0">Scheduler</h4>
      <div className="sw-tooltip tooltip-left" data-tooltip={minimiseButtonText}>
        <button className="sw-btn btn-icon" aria-label={minimiseButtonText} onClick={onMinimise}>
          <FontAwesomeIcon icon={['fas', minimised ? 'chevron-up' : 'chevron-down']} />
        </button>
      </div>
    </div>
  )

  const emptyList = (
    <div className="sw-empty p-6 bg-lightestgrey">
      <p className="h5">Nothing selected</p>
      <p>Start by clicking plan cells</p>
    </div>
  )

  const moduleLearnerLists = noCellsSelected ? (
    emptyList
  ) : (
    <>
      <ModuleList
        selectedCells={selectedCells}
        setSelectedCells={setSelectedCells}
        uneditableCells={uneditableCells}
        {...props}
        disabled={disableControls}
      />
      <LearnerList
        learnerUnavailabilities={learnerUnavailabilities}
        selectedCells={selectedCells}
        setSelectedCells={setSelectedCells}
        uneditableCells={uneditableCells}
        {...props}
        disabled={disableControls}
      />
    </>
  )

  const clashErrorContent = clashErrors ? (
    <ul className="my-0">
      {clashErrors.all.map((error, index) => (
        <li key={index} className="mt-0">
          {typeof error === 'string' ? error : error.message}
        </li>
      ))}
    </ul>
  ) : (
    []
  )

  const goToMultiplanView: MouseEventHandler = e => {
    e.stopPropagation()
    e.preventDefault()
    if (!showFindLearners) return
    const url = buildViewInOtherPlanUrl({
      trainingPlanId: showFindLearners?.planId,
      selectedCells,
      selectedSessionDuration,
      selectedTrainerId,
      moduleKeys: selectedCells.modules.map(m => m.key)
    })
    window.location.href = url
  }

  const footerContent = (
    <div className="flex justify-between gap-y-4 flex-wrap">
      {showFindLearners && (
        <button
          disabled={disableControls || selectedCells.modules.length === 0}
          className="sw-btn basis-full sw-tooltip"
          onClick={goToMultiplanView}
          data-tooltip={'From all plans at this company'}
        >
          Find Learners
          <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
        </button>
      )}
      <button disabled={disableControls} className="sw-btn" onClick={onClear}>
        <FontAwesomeIcon icon={['far', 'trash-can']} />
        Clear
      </button>
      <button
        disabled={isUnSubmittable}
        className={`sw-btn btn-primary submit-button${isSubmitting ? ' sw-loading' : ''}`}
        onClick={() => onSubmit({})}
      >
        {sessionCode ? 'Add Learners' : 'Create'}
        <FontAwesomeIcon icon={['fas', 'up-right-from-square']} />
      </button>
    </div>
  )

  return (
    <>
      <div className={`training-plan-scheduler${minimised ? '' : ' active'}`}>
        {headerContent}
        <div className="sw-divider my-2" />

        <div className="h-auto overflow-y-auto overflow-x-hidden">
          <SWCheckbox
            name="catchup"
            className={`ml-2 ${editingSession ? 'sw-readonly' : ''}`}
            checked={catchup}
            onChange={e => setCatchup(e.target.checked)}
            label="Catchup Session"
            disabled={editingSession}
          />

          <TrainerPicker
            assignedTrainers={assignedTrainers}
            trainerUnavailabilities={trainerUnavailabilities}
            selectedTrainerId={selectedTrainerId}
            onChange={changeTrainerState}
            disabled={disableControls}
            readonly={editingSession}
          />
          <div className="flex mb-2">
            <FindACoachButton
              disabled={disableControls}
              payload={buildNewSessionPayload({
                selectedTrainerId: '',
                selectedCells,
                selectedSessionDuration,
                permitChangeRequestClash: false,
                permitAvailabilityClash: false,
                catchup,
                autoStartHostedEnvironments
              })}
              companyId={companyId}
            />
          </div>

          <TimePeriodSelector
            timePeriod={selectedSessionDuration}
            onChange={onSchedulerDateChange}
            totalEstimatedDuration={totalEstimatedDuration}
            disabled={disableControls || editingSession}
            timeslots={timeslots}
          />

          {moduleLearnerLists}
          {errors && Object.keys(errors).length > 0 && (
            <ul className="text-error-text list-inside">{<Errors errors={errors} full={true} as="li" />}</ul>
          )}
        </div>

        <div className="sw-divider my-2" />
        {footerContent}
      </div>
      {!!clashErrors && (
        <ConfirmationModal
          title="Are you sure? There's some conflicts..."
          message={clashErrorContent}
          submitting={isSubmitting}
          onClose={() => {
            setClashErrors(undefined)
            setCellsSelectable(true)
          }}
          onConfirm={() =>
            onSubmit({
              permitAvailabilityClash: clashErrors.availabilityErrors.length > 0,
              permitChangeRequestClash: clashErrors.changeRequestErrors.length > 0
            })
          }
        />
      )}
    </>
  )
}

export default SchedulerPanel
