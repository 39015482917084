import { ToolbarProps } from '@skiller-whale/react-big-calendar'
import { navigate } from '@skiller-whale/react-big-calendar/lib/utils/constants'
import { Timezone } from '../generated_types/coach_availability'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

type Props = {
  editable: boolean
  setEditable?: (b: boolean) => void
  timezones?: Timezone[]
  timezone: Timezone
  setTimezone?: (tz: Timezone) => void
}

const Toolbar = ({ editable, setEditable, timezones, setTimezone, timezone }: Props) => {
  const navigationEnabled = !editable
  const result: React.ComponentType<ToolbarProps> = ({ localizer, label, onNavigate }) => {
    return (
      <div className="flex my-2 items-baseline justify-between gap-2">
        {navigationEnabled && (
          <>
            <div className="sw-btn-grp basis-0 grow">
              <button type="button" className="sw-btn btn-sm focus:ring-0" onClick={() => onNavigate(navigate.TODAY)}>
                {localizer.messages.today}
              </button>
              <button
                type="button"
                className="sw-btn btn-sm focus:ring-0"
                onClick={() => onNavigate(navigate.PREVIOUS)}
              >
                {localizer.messages.previous}
              </button>
              <button type="button" className="sw-btn btn-sm focus:ring-0" onClick={() => onNavigate(navigate.NEXT)}>
                {localizer.messages.next}
              </button>
            </div>

            <span className="text-center px-2">{label}</span>
          </>
        )}
        <div className="basis-0 grow text-right flex items-baseline justify-end gap-2">
          <label>Timezone:</label>
          {timezones && setTimezone && timezones.length > 1 ? (
            <select
              className="sw-select"
              value={timezone.value}
              onChange={e => setTimezone(timezones.find(tz => tz.value === e.currentTarget.value)!)}
            >
              {timezones.map(tz => (
                <option key={tz.value} value={tz.value}>
                  {tz.label}
                </option>
              ))}
            </select>
          ) : (
            timezone.label
          )}
        </div>
        {setEditable ? (
          <div>
            <button
              onClick={() => setEditable(!editable)}
              className="sw-btn btn-icon btn-sm"
              aria-label={editable ? 'Stop Editing' : 'Edit'}
            >
              {editable ? (
                <FontAwesomeIcon icon={['fas', 'check']} fixedWidth />
              ) : (
                <FontAwesomeIcon icon={['fas', 'pencil']} fixedWidth />
              )}
            </button>
          </div>
        ) : null}
      </div>
    )
  }

  result.displayName = 'Toolbar'

  return result
}
export default Toolbar
