import { Dispatch, MouseEventHandler, SetStateAction, useEffect, useState } from 'react'
import {
  CurriculaStatusesPageProps,
  CurriculumStatus,
  ModuleGrouping,
  ModuleStatus,
  PlanModuleUsage
} from '../generated_types/curriculum_status'
import { apiRequest } from '../utils/json_request'
import ListPicker from '../library/list_picker'
import SWCheckbox from '../library/sw/sw_checkbox'
import ChipList from './chip_list'
import qs from 'qs'

type Statuses = Record<string, CurriculumStatus | null>

const CurriculaStatusViewer = ({
  curricula,
  technologies,
  selected_curricula,
  selected_technologies,
  show_published: initialPublished
}: CurriculaStatusesPageProps) => {
  const [showPublished, setShowPublished] = useState(initialPublished ?? true)
  const [curriculumStatuses, setCurriculumStatuses] = useState<Statuses>({})
  const [curriculumDisplayed, setCurriculumDisplayed] = useState<Record<string, boolean>>({})

  const [technlogyStatuses, setTechnologyStatuses] = useState<Statuses>({})
  const [technlogyDisplayed, setTechnologyDisplayed] = useState<Record<string, boolean>>({})

  const loadModules = async (grouping: ModuleGrouping, setStatuses: Dispatch<SetStateAction<Statuses>>) => {
    // set a placeholder null value
    setStatuses(current => ({ ...current, [grouping.key]: null }))
    const response = await apiRequest(`/curricula/statuses/search`, {
      method: 'POST',
      payload: { module_keys: grouping.module_keys }
    })
    if (response.ok) {
      setStatuses(current => ({ ...current, [grouping.key]: response.data.curriculum }))
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const technologiesToLoad = technologies.filter(t => (selected_technologies || []).includes(t.key))
      const curriculaToLoad = curricula.filter(c => (selected_curricula || []).includes(c.key))

      setTechnologyDisplayed(Object.fromEntries(technologiesToLoad.map(t => [t.key, true])))
      setCurriculumDisplayed(Object.fromEntries(curriculaToLoad.map(c => [c.key, true])))

      for (const t of technologiesToLoad) {
        await loadModules(t, setTechnologyStatuses)
      }

      for (const c of curriculaToLoad) {
        await loadModules(c, setCurriculumStatuses)
      }
    }
    loadData()
  }, [selected_curricula, selected_technologies, curricula, technologies]) // in practice these props never change - they are the static props encoded in the html

  const toggleCurriculum = (key: string) => {
    setCurriculumDisplayed(({ [key]: existing, ...current }) => {
      const newValue = !existing
      if (newValue && !(key in curriculumStatuses)) {
        const curriculum = curricula.find(c => c.key == key)
        if (curriculum) {
          loadModules(curriculum, setCurriculumStatuses)
        }
      }
      return { ...current, [key]: newValue }
    })
  }

  const handleChipClick: MouseEventHandler<HTMLAnchorElement> = event => {
    const toRemove = event.currentTarget.dataset.key
    if (!toRemove) {
      return
    }
    toggleCurriculum(toRemove)

    event.preventDefault()
  }

  const toggleTechnology = (key: string) => {
    setTechnologyDisplayed(({ [key]: existing, ...current }) => {
      const newValue = !existing
      if (newValue && !(key in technlogyStatuses)) {
        const technology = technologies.find(c => c.key == key)
        if (technology) {
          loadModules(technology, setTechnologyStatuses)
        }
      }
      return { ...current, [key]: newValue }
    })
  }

  const handleTechnologyChipClick: MouseEventHandler<HTMLAnchorElement> = event => {
    const toRemove = event.currentTarget.dataset.key
    if (!toRemove) {
      return
    }
    toggleTechnology(toRemove)

    event.preventDefault()
  }

  const curriculaToDisplay = Object.entries(curriculumStatuses)
    .filter(([key, _stat]) => curriculumDisplayed[key])
    .map(([_, statusOrNull]) => statusOrNull)

  const technologiesToDisplay = Object.entries(technlogyStatuses)
    .filter(([key, _stat]) => technlogyDisplayed[key])
    .map(([_, statusOrNull]) => statusOrNull)

  useEffect(() => {
    updateUrl(curriculumDisplayed, technlogyDisplayed, showPublished)
  }, [curriculumDisplayed, technlogyDisplayed, showPublished])

  const modulesToDisplay = uniqueByModule(
    curriculaToDisplay.concat(technologiesToDisplay).flatMap(statusOrNull => (statusOrNull ? statusOrNull.modules : []))
  )

  const sortedAndFiltered = modulesToDisplay
    .filter(x => showPublished || !x.published)
    .sort((a, b) => compareModuleStatuses(a, b))

  useEffect(() => {
    const handler = (event: PopStateEvent) => {
      if (event.state) {
        const state = event.state as PopstatePayload

        setCurriculumDisplayed(Object.fromEntries(state.curricula.map(k => [k, true])))
        setTechnologyDisplayed(Object.fromEntries(state.technologies.map(k => [k, true])))

        setShowPublished(state.published)
      }
    }
    window.addEventListener('popstate', handler)

    return () => window.removeEventListener('popstate', handler)
  })

  return (
    <>
      <h1>Curricula Statuses</h1>

      <div className="sw-group">
        <SWCheckbox
          checked={showPublished}
          onChange={e => setShowPublished(e.target.checked)}
          label="Show Published"
          className="sw-input"
        />
      </div>

      <div className="flex flex-col gap-2 mt-2">
        <div>
          <label htmlFor="curricula-picker" className="sw-label">
            Curriculum:
          </label>
          <ListPicker id="curricula-picker" onAdd={key => toggleCurriculum(key)}>
            {curricula
              .filter(c => !curriculumDisplayed[c.key])
              .map(c => (
                <option value={c.key} key={c.key}>
                  {c.title}
                </option>
              ))}
          </ListPicker>

          <ChipList
            groupings={curricula.filter(c => curriculumDisplayed[c.key])}
            statuses={curriculumStatuses}
            handleChipClick={handleChipClick}
          />
        </div>

        <div>
          <label htmlFor="technology-picker" className="sw-label">
            Technology:
          </label>
          <ListPicker id="technology-picker" onAdd={key => toggleTechnology(key)}>
            {technologies
              .filter(c => !technlogyDisplayed[c.key])
              .map(c => (
                <option value={c.key} key={c.key}>
                  {c.title}
                </option>
              ))}
          </ListPicker>

          <ChipList
            groupings={technologies.filter(c => technlogyDisplayed[c.key])}
            statuses={technlogyStatuses}
            handleChipClick={handleTechnologyChipClick}
          />
        </div>
      </div>

      <div className="sw-snippet block mb-0">
        <p>
          For each selected curricula you can see all the modules, their status, the active plans they are used in and
          the minimum <em>urgency</em> across the listed plans, sorted by urgency.
        </p>
        <p>
          The urgency for a plan is the per user average number of modules before this module times the plan frequency,
          in weeks.
        </p>
        <p>
          For example if a module is the first non completed, blue/yellow cell for a user, then the urgency for that
          user is 0. If for the next user there are two things they will be taught first (according to the plan order) &
          the plan frequency is fortnightly then the urgency is 4. if these were the only users in the plan, the urgency
          would be 2.
        </p>
      </div>
      {sortedAndFiltered.length > 0 && (
        <table className="sw-table mt-4 mx-auto">
          <thead>
            <tr>
              <th>Curriculum</th>
              <th>Module</th>
              <th>Published</th>
              <th>Exists</th>
              <th>Urgency</th>
              <th>Plans</th>
            </tr>
          </thead>
          <tbody>
            {sortedAndFiltered.map(m => (
              <tr key={m.key}>
                <td>{m.curriculum_title}</td>
                <td>{m.title}</td>
                <td>{m.published ? '✅' : '❌'}</td>
                <td>{m.exists ? '✅' : '❌'}</td>
                <td>{m.urgency && Math.round(100 * m.urgency) / 100}</td>
                <td>
                  <details className="sw-accordion simple">
                    <summary>
                      <PlanCount plans={m.plans} />
                    </summary>
                    <ul>
                      {m.plans
                        .filter(p => p.urgency !== null)
                        .map(p => (
                          <li key={p.plan_id}>
                            <a href={`/plans/${p.plan_id}`}>
                              {p.plan_id} ({p.number_of_relevant_learners} learners)
                              {p.pending && ' (pending)'}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </details>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  )
}

const compareModuleStatuses = (left: ModuleStatus, right: ModuleStatus) => {
  if (left.urgency === null && right.urgency === null) {
    return 0
  }
  if (left.urgency === null) {
    return 1
  }
  if (right.urgency === null) {
    return -1
  }
  return left.urgency - right.urgency
}

const uniqueByModule = (statuses: ModuleStatus[]): ModuleStatus[] => {
  const result: ModuleStatus[] = []
  const seen = new Set<string>()

  statuses.forEach(s => {
    if (seen.has(s.key)) return
    seen.add(s.key)
    result.push(s)
  })

  return result
}

type PopstatePayload = {
  curricula: string[]
  technologies: string[]
  published: boolean
}

const updateUrl = (
  curriculumDisplayed: Record<string, boolean>,
  technologyDisplayed: Record<string, boolean>,
  showPublished: boolean
) => {
  const curriculumKeys = Object.entries(curriculumDisplayed)
    .flatMap(([key, value]) => (value ? [key] : []))
    .sort()
  const technologyKeys = Object.entries(technologyDisplayed)
    .flatMap(([key, value]) => (value ? [key] : []))
    .sort()

  const payload: PopstatePayload = {
    curricula: curriculumKeys,
    technologies: technologyKeys,
    published: showPublished
  }

  const query = qs.stringify(payload, { arrayFormat: 'brackets' })
  const newURL = new URL(`?${query}`, window.location.href)

  // this case occurs during a popstate event: the browser has updated the url to the new value
  // and has supplied the matching state. if we were to pushState here
  // then we'd end up with a duplicate that would prevent going further back (since each back navigation would add a new history item)
  if (newURL.toString() === window.location.href) {
    return
  }
  history.pushState(payload, '', newURL)
}

type PlanCountProps = {
  plans: PlanModuleUsage[]
}
const PlanCount = ({ plans }: PlanCountProps) => {
  const withUrgency = plans.filter(p => p.urgency !== null)
  const pending = withUrgency.filter(p => p.pending)

  if (pending.length > 0) {
    return `${withUrgency.length} (${pending.length} pending)`
  } else {
    return `${withUrgency.length}`
  }
}

export default CurriculaStatusViewer
