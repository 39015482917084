import React, { useState, useCallback, useRef, useEffect, useMemo, Dispatch, SetStateAction } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import EditPlanModuleModal from './edit_plan_module_modal'
import TrainingPlanHeader from './training_plan_header'
import TrainingPlanFooter from './training_plan_footer'
import PlannedSessionRow, { UpdateDoNotScheduleFunction } from './planned_session_row'
import SchedulerPanel from './scheduler_panel/scheduler_panel'
import HiddenSessionWarning from './hidden_sessions_warning'
import { consumedCredits, perLearnerPlanCost } from './plan_cost'
import PlanCostDisplay from './plan_cost_display'
import PerLearnerCostDisplay from './per_learner_cost_display'
import {
  buildRevertibleState,
  fromDBState,
  serializePlan,
  discardQueryParametersFromUrl,
  attendancesChanged,
  warnOfChanges,
  determinePlanDependencies,
  parseAttendances,
  hiddenState,
  fetchSessionStatuses,
  calculateCompleteUserIds,
  mergePerModuleAndUserData,
  currentAttendanceRequirement,
  currentAttendance,
  deepMergeSessionData,
  removeSessionsByCode
} from './session_editor_helpers'

import AddUser from '../components/add_user'
import PlanModulePicker from '../components/plan_module_picker'
import { cycleState, emptyAttendanceState } from '../states'
import Toggle from '../../library/toggle'
import {
  Modules,
  PlannedSession,
  TrainingPlan,
  TrainingPlanContext,
  TrainingPlanSaveState,
  Learner,
  PlannedSessionStatus,
  UserFilter,
  SessionWarning
} from '../types'
import { PersistedTrainingPlan, Sessions, SharedNotes, TrainingPlanUsers } from '../../generated_types/training_plan'
import ErrorsToast from '../../library/errors_toast'
import useScheduler from './scheduler_panel/use_scheduler'
import jsonRequest from '../../utils/json_request'
import CoachingPlanPresence from '../components/coaching_plan_presence'
import UpdateCellWithChangeRequestModal from './update_cell_with_change_request_modal'
import { usePlanVersion } from '../components/use_plan_version'
import CoachingPlanVersionWarning from '../components/coaching_plan_version_warning'
import SessionStatusInfoModal from './session_status_info_modal'
import CreditsConsumedPerLearner from './credits_consumed_per_learner'
import { ChannelEventHandler, useCoachingPlanChannel } from '../components/use_coaching_plan_channel'
import { FlashAlert } from '../../library/flash_message'
import MoveUsersLink from './move_users_link'

interface SessionEditorProps {
  persistedPlan?: TrainingPlan
  trainingPlan: TrainingPlan
  known_module_keys: string[]
  modules: Modules
  setModules: Dispatch<SetStateAction<Modules>>
  editable: boolean
  setLearners: Dispatch<SetStateAction<Array<Learner>>>
  setSessions: Dispatch<SetStateAction<Sessions>>
  initialUserIdsAddedFromAssessment: number[]
  initialMovedTrainingPlanUserIds: number[]
  indefinitelyPausedUserIds: number[]
  defaultTrainerId?: number
  showScheduler: boolean
  setShowScheduler: Dispatch<SetStateAction<boolean>>
  showCRResponse?: boolean
  availableLearners: TrainingPlanUsers
  showPlanCosts: boolean
}

const SessionEditor = ({
  persistedPlan,
  trainingPlan,
  setSessions,
  known_module_keys,
  modules,
  setModules,
  editable,
  setLearners,
  initialUserIdsAddedFromAssessment,
  initialMovedTrainingPlanUserIds,
  indefinitelyPausedUserIds,
  defaultTrainerId,
  showScheduler,
  setShowScheduler: _setShowScheduler,
  showCRResponse,
  availableLearners: available_learners,
  showPlanCosts
}: SessionEditorProps) => {
  const {
    id: trainingPlanId,
    company_id: companyId,
    learners,
    planned_sessions: initialSessions,
    sessions
  } = trainingPlan

  const previewMode = trainingPlanId === null
  // lock version is the activerecord optimistic locking version
  const { lockVersions, planUpdated } = usePlanVersion()
  // version is part of the client side 'do i have any unsaved changes' tracking
  const [version, setVersion] = useState(0)
  const [persistedVersion, setPersistedVersion] = useState(0)
  const [revertibleState, setRevertibleState] = useState(() => {
    if (persistedPlan) {
      //persistedPlan represents the state of the DB, when that differs from the plan we wish to display.
      //In this case the trainingPlan prop may contain unsaved changes for the user to review.
      return buildRevertibleState(persistedPlan)
    } else {
      return buildRevertibleState(trainingPlan)
    }
  })

  const [plannedSessions, setPlannedSessions] = useState(fromDBState(initialSessions))
  const [sharedNotes, setSharedNotes] = useState(trainingPlan.shared_notes)
  const [showHidden, setShowHidden] = useState(false)
  const [saveState, setSaveState] = useState<TrainingPlanSaveState>({})
  const [userIdsAddedFromAssessment, setUserIdsAddedFromAssessment] = useState(initialUserIdsAddedFromAssessment)
  const [movedTrainingPlanUserIds, setMovedTrainingPlanUserIds] = useState(initialMovedTrainingPlanUserIds)
  const [selectedUserIds, setSelectedUserIds] = useState(new Set<number>())
  const [userIdsToRemove, setUserIdsToRemove] = useState(new Set<number>())
  const [userIdsMoved, setUserIdsMoved] = useState(new Set<number>())
  const [changeRequestIdsToReject, setChangeRequestIdsToReject] = useState<number[]>([])

  const [editingSessionKey, setEditingSessionKey] = useState<string | undefined>(undefined)

  const [displayEditCellModalFor, setDisplayEditCellModalFor] = useState<
    { userId: number; moduleKey: string } | undefined
  >()

  const [displaySessionInfoModalFor, setDisplaySessionInfoModalFor] = useState<
    { userId: number; moduleKey: string } | undefined
  >()

  const { learnerUnavailabilities, selectedSessionDuration, selectedCells, toggleSelectedCell } = useScheduler()

  const [cellsSelectable, setCellsSelectable] = useState(showScheduler)

  const { addListener: addChannelListener, removeListener: removeChannelListener } = useCoachingPlanChannel()

  const userIdsInPlan = learners.map(learner => learner.user_id)
  const previousUserIds = useRef(revertibleState.learners.map(l => l.user_id))

  const hiddenSessionCount = plannedSessions.filter(ps => hiddenState(ps.status)).length

  useEffect(() => {
    previousUserIds.current = learners.map(l => l.user_id)
  }, [learners])

  const newAddedUserIds = useMemo(() => {
    if (previousUserIds.current) {
      return learners.map(l => l.user_id).filter(id => previousUserIds.current.indexOf(id) < 0)
    } else {
      return []
    }
  }, [learners])

  const planDependencies = useMemo(
    () =>
      determinePlanDependencies({
        userIds: learners.map(l => l.user_id),
        modules,
        plannedSessions: plannedSessions,
        selectedDuration: selectedSessionDuration,
        sharedNotes,
        sessions
      }),
    [modules, plannedSessions, learners, selectedSessionDuration, sharedNotes, sessions]
  )

  const [showIndefinitelyPaused, setShowIndefinitelyPaused] = useState(!showScheduler)
  // we show complete learners on page loadif:
  // - you're on the editor view (ie if defaultToScheduler is false)
  // - editable is false: this is a proxy for shownig all learners by default to managers
  const [showCompleteLearners, setShowCompleteLearners] = useState(!showScheduler || !editable)

  const [dismissibleWarnings, setDismissibleWarnings] = useState<SessionWarning[]>([])

  const setShowScheduler = useCallback(
    newValue => {
      _setShowScheduler(newValue)
      if (newValue === false) {
        setShowCompleteLearners(true)
        setShowIndefinitelyPaused(true)
      }
    },
    [_setShowScheduler]
  )

  const indefinitePauses: UserFilter = {
    show: showIndefinitelyPaused,
    userIds: indefinitelyPausedUserIds
  }

  const completeUsers: UserFilter = {
    show: showCompleteLearners,
    userIds: calculateCompleteUserIds(
      userIdsInPlan,
      plannedSessions,
      trainingPlan.invite_optional,
      sharedNotes,
      sessions
    )
  }

  type UpdateSessionsCallback = (newSessions: PlannedSession[]) => void

  const updatePlannedSessions = useCallback((transform: UpdateSessionsCallback) => {
    setPlannedSessions(oldValue => {
      const newSessions = [...oldValue]
      transform(newSessions)
      return newSessions
    })
    setVersion(current => current + 1)
  }, [])

  const selectCellEnabled = editable && cellsSelectable

  const selectCell = useCallback(
    (userId: number, plannedSession: PlannedSession) => {
      if (selectCellEnabled) {
        toggleSelectedCell(
          userId,
          plannedSession,
          sharedNotes[plannedSession.training_module],
          sessions[plannedSession.training_module]
        )
      }
    },
    [selectCellEnabled, toggleSelectedCell, sharedNotes, sessions]
  )

  const handleCellClick = useCallback(
    ({
      groupIndex: _groupIndex,
      userIndexInGroup,
      sessionIndex
    }: {
      groupIndex: number
      userIndexInGroup: number
      sessionIndex: number
    }) => {
      const userId = learners[userIndexInGroup].user_id // this view only ever has one group so we can ignore group index
      if (showScheduler) selectCell(userId, plannedSessions[sessionIndex])
      else {
        const module_key = plannedSessions[sessionIndex].training_module
        const sessionsForUser = sessions[module_key]?.[userId]

        updatePlannedSessions(newSessions => {
          const session = { ...newSessions[sessionIndex] }
          session.attendances = {
            ...session.attendances,
            [userId]: cycleState(session.attendances[userId], sessionsForUser)
          }
          newSessions[sessionIndex] = session
        })
      }
    },
    [learners, showScheduler, selectCell, plannedSessions, updatePlannedSessions, sessions]
  )

  const handleSave = async () => {
    setSaveState({ inProgress: true })

    const response = await jsonRequest<unknown, undefined, { training_plan: PersistedTrainingPlan }>(
      `/plans/${trainingPlanId}`,
      {
        method: 'PATCH',
        payload: {
          training_plan: serializePlan(
            plannedSessions,
            learners,
            userIdsToRemove,
            orderingChanged,
            lockVersions[trainingPlanId!]
          ),
          user_ids_added_from_assessment: userIdsAddedFromAssessment,
          moved_training_plan_user_ids: movedTrainingPlanUserIds,
          rejected_change_request_ids: changeRequestIdsToReject
        }
      }
    )

    if (!response.ok) {
      setSaveState({ errors: response.errors })
      return
    }
    const data = response.data
    const reloadedSessions = fromDBState(data.training_plan.planned_sessions)
    planUpdated({ newVersion: data.training_plan.lock_version, planId: data.training_plan.id })
    setRevertibleState({
      plannedSessions: reloadedSessions,
      learners: data.training_plan.learners
    })
    setPersistedVersion(version)
    setPlannedSessions(reloadedSessions)
    setLearners(data.training_plan.learners)
    setUserIdsToRemove(new Set())
    setUserIdsMoved(new Set())
    setUserIdsAddedFromAssessment([])
    setMovedTrainingPlanUserIds([])
    setSaveState({ success: true })
    discardQueryParametersFromUrl()
    setChangeRequestIdsToReject([])
  }

  const toggleSessionDeletion = useCallback(
    (index: number) => {
      updatePlannedSessions(newSessions => {
        const current = newSessions[index]
        newSessions[index] = { ...current, markedForDestruction: !current.markedForDestruction }
      })
    },
    [updatePlannedSessions]
  )

  const toggleSessionVisibility = useCallback(
    (index: number) => {
      updatePlannedSessions(newSessions => {
        const current = newSessions[index]

        const originalStatus = current.dbState?.status

        // when toggling visibility from a hidden state, life is easy: the new status is always included
        // In the reverse direction, if the originalStatus was a hidden status we use that one, ie if in the ui you unhide an implicitly hidden row
        // and then change your mind and rehide it, then it will remain implicitly hidden
        let newStatus: PlannedSessionStatus
        if (current.status === PlannedSessionStatus.Included) {
          newStatus =
            originalStatus === PlannedSessionStatus.ImplicitlyHidden
              ? originalStatus
              : PlannedSessionStatus.ExplicitlyHidden
        } else {
          newStatus = PlannedSessionStatus.Included
        }
        newSessions[index] = { ...current, status: newStatus }
      })
    },
    [updatePlannedSessions]
  )

  const startEditing = useCallback((session: PlannedSession) => {
    setEditingSessionKey(session.training_module)
  }, [])

  const updateSessionModule = (fromModule: string, toModule: string) => {
    updatePlannedSessions(newSessions => {
      const editingSessionIndex = newSessions.findIndex(session => session.training_module === fromModule)
      if (editingSessionIndex >= 0) {
        const current = newSessions[editingSessionIndex]
        newSessions[editingSessionIndex] = { ...current, training_module: toModule }
      }
    })

    if (trainingPlanId) {
      //this is actually always true - we don't allow editing of plan previews
      fetchSessionStatuses({ trainingPlanId, userIds: userIdsInPlan, module: toModule }).then(
        ({ planned_session: plannedSessionForNewModule, modules: newModules, shared_notes, sessions }) => {
          updatePlannedSessions(currentPlannedSessions => {
            const toUpdateIndex = currentPlannedSessions.findIndex(session => session.training_module === toModule)
            if (toUpdateIndex >= 0) {
              const session = currentPlannedSessions[toUpdateIndex]

              /* the bit in the new data from the backend that isn't right is the attendance requirement, so copy
              all the attendance requirements into the new data */
              const updatedAttendances = plannedSessionForNewModule.attendances

              Object.entries(session.attendances).forEach(([user_id, oldAttendance]) => {
                if (user_id in updatedAttendances) {
                  updatedAttendances[user_id].attendance_requirement = oldAttendance.attendance_requirement
                } else {
                  updatedAttendances[user_id] = {
                    change_requests: [],
                    attendance_requirement: oldAttendance.attendance_requirement
                  }
                }
              })

              currentPlannedSessions[toUpdateIndex] = { ...session, attendances: updatedAttendances }
            }
          })
          setModules(current => ({ ...current, ...newModules }))
          addSessions(sessions)
          updateSharedNotes(shared_notes)
        }
      )
    }

    setEditingSessionKey(undefined)
  }

  const updateOrdering = useCallback(
    (fromIndex: number, toIndex: number) => {
      updatePlannedSessions(sessions => {
        let [toMove] = sessions.splice(fromIndex, 1)
        toMove = { ...toMove, reordered: toIndex !== toMove.dbState?.index }
        sessions.splice(toIndex, 0, toMove)
      })
      setVersion(current => current + 1)
    },
    [updatePlannedSessions]
  )

  const updateDoNotSchedule: UpdateDoNotScheduleFunction = useCallback(
    ({ userId, reason, moduleKey, doNotSchedule }) => {
      updatePlannedSessions(current => {
        const toUpdateIndex = current.findIndex(session => session.training_module === moduleKey)
        if (toUpdateIndex >= 0) {
          const plannedSession = current[toUpdateIndex]
          const existingAttendance = plannedSession.attendances[userId] || emptyAttendanceState
          const updatedSession = {
            ...plannedSession,
            attendances: {
              ...plannedSession.attendances,
              [userId]: {
                ...existingAttendance,
                do_not_schedule: doNotSchedule,
                do_not_schedule_reason: reason
              }
            }
          }
          current[toUpdateIndex] = updatedSession
        }
      })
    },
    [updatePlannedSessions]
  )

  const orderingChanged =
    learners.length !== revertibleState.learners.length ||
    learners.some((learner, index) => revertibleState.learners[index].user_id !== learner.user_id)

  const sessionsChanged = !!plannedSessions.find((session, index) => {
    if (session.dbState) {
      return (
        session.dbState.index !== index || // session has been reordered
        session.markedForDestruction || // session will be destroyed
        session.dbState.training_module !== session.training_module || // the module has been edited
        session.dbState.status !== session.status || //the session has been hidden/unhidden
        attendancesChanged(session.dbState.attendances, session.attendances)
      )
    } else {
      // unsaved row - has by definition changed, unless it is already marked for destructions
      return !session.markedForDestruction
    }
  })

  const hasChanges =
    orderingChanged || sessionsChanged || userIdsToRemove.size > 0 || changeRequestIdsToReject.length > 0

  useEffect(() => {
    if (hasChanges) {
      window.addEventListener('beforeunload', warnOfChanges)
    }
    return () => {
      window.removeEventListener('beforeunload', warnOfChanges)
    }
  }, [hasChanges])

  const trainingPlanContext: TrainingPlanContext = {
    companyId,
    modules,
    editable,
    hasChanges,
    available_learners,
    learners: learners,
    newAddedUserIds,
    userIdsToRemove,
    defaultTrainerId,
    trainingPlanId: trainingPlanId
  }

  const plannedSessionsRows = plannedSessions.map((session, sessionIndex) => {
    if (hiddenState(session.dbState?.status) && !showHidden) {
      // doing this (instead of filtering plannedSessions) means that the sessionIndex for a session doesn't vary depending on hidden-ness
      return null
    }
    return (
      <PlannedSessionRow
        key={session.id || session.training_module}
        trainingPlanContext={trainingPlanContext}
        session={session}
        learnerUnavailability={learnerUnavailabilities}
        usersDependencies={planDependencies[session.training_module]}
        selectedCells={selectedCells}
        sessionIndex={sessionIndex}
        indefinitePauses={indefinitePauses}
        completeUsers={completeUsers}
        cellClicked={handleCellClick}
        toggleSessionVisibility={toggleSessionVisibility}
        toggleSessionDeletion={toggleSessionDeletion}
        updateOrdering={updateOrdering}
        startEditing={startEditing}
        editable={editable}
        scheduler={showScheduler}
        changeRequestIdsToReject={changeRequestIdsToReject}
        setChangeRequestIdsToReject={setChangeRequestIdsToReject}
        learnerGroups={[learners]}
        sharedNotesForModule={sharedNotes[session.training_module] || {}}
        setSharedNotes={setSharedNotes}
        sessionsForModule={sessions[session.training_module]}
        updateDoNotSchedule={updateDoNotSchedule}
        setDisplayEditCellModalFor={setDisplayEditCellModalFor}
        setDisplaySessionInfoModalFor={setDisplaySessionInfoModalFor}
      />
    )
  })

  const userIdsInPlanSet = new Set(userIdsInPlan)
  const addableUsers = Object.values(available_learners)
    .filter(user => !userIdsInPlanSet.has(user.id))
    .sort((u1, u2) => u1.name.localeCompare(u2.name, 'en', { sensitivity: 'base' }))

  const handleRevert = useCallback(() => {
    setPlannedSessions(revertibleState.plannedSessions)
    setLearners(revertibleState.learners)
    setUserIdsToRemove(new Set())
    setUserIdsMoved(new Set())
    setUserIdsAddedFromAssessment([])
    setMovedTrainingPlanUserIds([])
    discardQueryParametersFromUrl()
    setChangeRequestIdsToReject([])
  }, [revertibleState, setLearners])

  let saveButtonClasses = 'sw-btn btn-primary mr-2'
  if (saveState.inProgress) {
    saveButtonClasses += ' sw-loading'
  }

  const reorderUsers = useCallback(
    (fromIndex: number, toIndex: number) => {
      setLearners(learners => {
        const newLearners = learners.concat()
        const [toMove] = newLearners.splice(fromIndex, 1)

        setUserIdsMoved(currentMovedIds => {
          const result = new Set([...currentMovedIds])
          // Depending on the moved column's new position relative to where it started, add or remove it from the moved list
          toIndex !== revertibleState.learners.findIndex(learner => learner.user_id === toMove.user_id)
            ? result.add(toMove.user_id)
            : result.delete(toMove.user_id)
          return result
        })

        newLearners.splice(toIndex, 0, toMove)
        return newLearners
      })
      setVersion(current => current + 1)
    },
    [revertibleState.learners, setLearners]
  )

  const toggleUserSelected = useCallback((userId: number) => {
    setSelectedUserIds(current => {
      const result = new Set([...current])
      if (!result.delete(userId)) {
        result.add(userId)
      }
      return result
    })
  }, [])

  const toggleUserDeleted = useCallback((userId: number) => {
    setUserIdsToRemove(current => {
      const result = new Set([...current])
      if (!result.delete(userId)) {
        result.add(userId)
      }
      return result
    })
    setVersion(current => current + 1)
  }, [])

  const updateSharedNotes = (newData: SharedNotes) => {
    setSharedNotes(current => {
      return mergePerModuleAndUserData(current, newData)
    })
  }

  const addSessions = useCallback(
    (newData: Sessions) => {
      setSessions(current => {
        return deepMergeSessionData(current, newData)
      })
    },
    [setSessions]
  )

  const removeSession = useCallback(
    (code: string) => {
      setSessions(current => removeSessionsByCode(current, code))
    },
    [setSessions]
  )

  const editingSession = plannedSessions.find(session => session.training_module === editingSessionKey)
  const remainingPerLearner = perLearnerPlanCost({
    plannedSessions,
    modules,
    available_learners,
    includeHiddenSessions: showHidden,
    indefinitePauses,
    sharedNotes,
    sessions
  })

  const consumedPerLearner = consumedCredits(sessions)

  const displayedLearners = learners.filter(learner => {
    if (!available_learners[learner.user_id]) return false
    if (!completeUsers.show && completeUsers.userIds.includes(learner.user_id)) return false
    if (!indefinitePauses.show && indefinitePauses.userIds.includes(learner.user_id)) return false
    return true
  })

  useEffect(() => {
    const handler: ChannelEventHandler = event => {
      if (event.type === 'session_updated') {
        removeSession(event.payload.code)
        addSessions(event.payload.details)
        if (event.payload.action === 'created' && !event.payload.triggered_by?.self) {
          const modulesKeys = Object.keys(event.payload.details)
          const moduleNames = modulesKeys.map(key => (key in modules ? modules[key].title : key)).join(', ')
          setDismissibleWarnings(current =>
            current.concat({
              index: warningIndex++,
              message: `A new session ${event.payload.code} on ${moduleNames} has been created for this plan by ${event.payload.triggered_by?.name}`
            })
          )
        }
      }
    }
    addChannelListener(handler)

    return () => removeChannelListener(handler)
  }, [addSessions, removeSession, addChannelListener, removeChannelListener, modules])

  return (
    <>
      {editable && (
        <div className="table-controls sw-snippet">
          <div className="flex items-center gap-2">
            Scheduler View
            <Toggle
              id="scheduler-editor-toggle"
              checked={!showScheduler}
              onChange={value => {
                setShowScheduler(!value)
                setCellsSelectable(!value)
              }}
              label="Editor View"
              disabled={hasChanges}
            />
          </div>
          {selectedUserIds.size > 0 && (
            <div className="my-2 flex gap-2">
              <button
                className="sw-btn"
                onClick={() => {
                  setSelectedUserIds(selected => {
                    selected.forEach(id => toggleUserDeleted(id))
                    return new Set()
                  })
                }}
              >
                <FontAwesomeIcon icon={['far', 'trash-can']} />
                Delete {selectedUserIds.size} user{selectedUserIds.size > 1 && 's'}
              </button>
              <MoveUsersLink hasChanges={hasChanges} selectedUserIds={selectedUserIds} learners={learners} />
            </div>
          )}
          <div className="flex flex-col">
            <Toggle
              id="hidden-sessions-toggle"
              disabled={hiddenSessionCount === 0}
              checked={showHidden}
              onChange={setShowHidden}
              label={`Show Hidden Sessions (${hiddenSessionCount})`}
              classNames="mr-0"
            />
            <Toggle
              id="indefinitely-paused-toggle"
              disabled={indefinitePauses.userIds.length === 0}
              checked={showIndefinitelyPaused}
              onChange={setShowIndefinitelyPaused}
              label={`Show Indefinitely Paused Users (${indefinitePauses.userIds.length})`}
              classNames="mr-0"
            />
            <Toggle
              id="complete-learners-toggle"
              disabled={completeUsers.userIds.length === 0}
              checked={showCompleteLearners}
              onChange={setShowCompleteLearners}
              label={`Show Completed Users (${completeUsers.userIds.length})`}
              classNames="mr-0"
            />
          </div>
        </div>
      )}

      <HiddenSessionWarning userIds={newAddedUserIds} plannedSessions={plannedSessions} />

      <div className="table-wrapper">
        <div id="table-menus" /> {/* Portal target for header menus (see TrainingPlanHeader) */}
        <DndProvider backend={HTML5Backend}>
          <table className="sw-simple-table training-plan h-[1px]">
            {/* the 1 px height makes the header cells take the full height of the th - it doesn't actually make the table 1px tall*/}
            <TrainingPlanHeader
              learners={learners}
              newAddedUserIds={newAddedUserIds}
              available_learners={available_learners}
              updateOrdering={reorderUsers}
              editable={editable}
              toggleUserSelected={toggleUserSelected}
              toggleUserDeleted={toggleUserDeleted}
              userIdsToRemove={userIdsToRemove}
              selectedUserIds={selectedUserIds}
              scheduler={showScheduler}
              planHasChanges={hasChanges}
              userIdsMoved={userIdsMoved}
              indefinitePauses={indefinitePauses}
              completeUsers={completeUsers}
            />
            <tbody>
              {plannedSessionsRows}
              {showPlanCosts && (
                <>
                  <PerLearnerCostDisplay
                    learners={displayedLearners}
                    remainingPerLearner={remainingPerLearner}
                    editable={editable}
                  />
                  <CreditsConsumedPerLearner
                    learners={displayedLearners}
                    consumedPerLearner={consumedPerLearner}
                    editable={editable}
                  />
                </>
              )}
            </tbody>
            <TrainingPlanFooter
              learners={displayedLearners}
              editable={editable}
              newAddedUserIds={newAddedUserIds}
              userIdsToRemove={userIdsToRemove}
            />
          </table>
        </DndProvider>
      </div>
      {showPlanCosts && (
        <PlanCostDisplay
          remainingPerLearner={remainingPerLearner}
          consumedPerLearner={consumedPerLearner}
          trainingPlanContext={trainingPlanContext}
        />
      )}
      {editable && !showScheduler && !previewMode && (
        <div className="flex flex-wrap gap-4 mx-auto max-w-7xl padded-content">
          <AddUser
            trainingPlanId={trainingPlanId}
            availableUsers={addableUsers}
            setLearners={setLearners}
            updatePlannedSessions={updatePlannedSessions}
            moduleKeys={plannedSessions.map(ps => ps.training_module)}
            updateSharedNotes={updateSharedNotes}
            addSessions={addSessions}
          />
          <PlanModulePicker
            trainingPlanId={trainingPlanId}
            plannedSessions={plannedSessions}
            userIdsInPlan={userIdsInPlan}
            updatePlannedSessions={updatePlannedSessions}
            known_module_keys={known_module_keys}
            setModules={setModules}
            updateSharedNotes={updateSharedNotes}
            addSessions={addSessions}
          />
        </div>
      )}
      {editable && (
        <div className="my-2 mx-auto max-w-7xl">
          {hasChanges && (
            <>
              <button disabled={saveState.inProgress} className={saveButtonClasses} onClick={handleSave}>
                <FontAwesomeIcon icon={['far', 'floppy-disk']} />
                Save
              </button>

              <button className="sw-btn" onClick={handleRevert}>
                <FontAwesomeIcon icon={['fas', 'rotate-left']} />
                Revert All
              </button>
            </>
          )}
          {!hasChanges && saveState.success && persistedVersion === version && (
            <div className="p-2 inline-block rounded-md animate-success">Changes saved</div>
          )}
          {saveState.errors && <ErrorsToast errors={saveState.errors} />}
        </div>
      )}
      {editingSession && (
        <EditPlanModuleModal
          known_module_keys={known_module_keys}
          session={editingSession}
          sessions={plannedSessions}
          cancelEdit={() => setEditingSessionKey(undefined)}
          updateSessionModule={updateSessionModule}
        />
      )}
      {showScheduler && editable && (
        <SchedulerPanel
          modules={modules}
          dependencies={planDependencies}
          attendances={parseAttendances(plannedSessions)}
          sharedNotes={sharedNotes}
          sessions={sessions}
          companyId={companyId}
          setCellsSelectable={setCellsSelectable}
          autoStartHostedEnvironments={trainingPlan.auto_start_hosted_environments}
          showFindLearners={trainingPlanId ? { planId: trainingPlanId } : undefined}
        />
      )}
      {editable && !previewMode && <CoachingPlanPresence />}
      {editable && displayEditCellModalFor && (
        <UpdateCellWithChangeRequestModal
          key={`${displayEditCellModalFor.userId}-${displayEditCellModalFor.moduleKey}`}
          moduleKey={displayEditCellModalFor.moduleKey}
          user={available_learners[displayEditCellModalFor.userId]}
          module={modules[displayEditCellModalFor.moduleKey]}
          currentState={currentAttendanceRequirement({ ...displayEditCellModalFor, plannedSessions })}
          onClose={() => setDisplayEditCellModalFor(undefined)}
          onConfirm={({ userId, moduleKey, requirement }) => {
            setDisplayEditCellModalFor(undefined)
            const index = plannedSessions.findIndex(ps => ps.training_module === moduleKey)
            if (index < 0) return

            updatePlannedSessions(newSessions => {
              const plannedSession = { ...newSessions[index] }
              const updatedAttendance = { ...(plannedSession.attendances[userId] || emptyAttendanceState) }
              updatedAttendance.attendance_requirement = requirement
              updatedAttendance.create_change_request = true
              plannedSession.attendances = {
                ...plannedSession.attendances,
                [userId]: updatedAttendance
              }
              newSessions[index] = plannedSession
            })
          }}
        />
      )}
      {displaySessionInfoModalFor && (
        <SessionStatusInfoModal
          learnerName={available_learners[displaySessionInfoModalFor.userId].name}
          sessionTitle={modules[displaySessionInfoModalFor.moduleKey].title}
          sessionUserData={currentAttendance({ ...displaySessionInfoModalFor, plannedSessions })}
          sessionStatuses={sessions[displaySessionInfoModalFor.moduleKey]?.[displaySessionInfoModalFor.userId]}
          showCRResponse={showCRResponse}
          onClose={() => setDisplaySessionInfoModalFor(undefined)}
        />
      )}
      {editable && !previewMode && <CoachingPlanVersionWarning />}
      {dismissibleWarnings.map(warning => (
        <FlashAlert
          key={warning.index}
          sticky
          className="animate-[bounce_1s_1.5]"
          onClose={() => setDismissibleWarnings(current => current.filter(m => m.index !== warning.index))}
        >
          <span className="text-xl">{warning.message}</span>
        </FlashAlert>
      ))}
    </>
  )
}

let warningIndex = 0

export default SessionEditor
