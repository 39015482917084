import { Controller } from '@hotwired/stimulus'

/**
 * Toggles CSS 'open' classes on a "control" element and a "reveal" outlet element
 */
export default class extends Controller<HTMLElement> {
  dismiss() {
    const form = this.element.closest('form')
    this.element.closest('.sw-chip')?.remove()
    form?.requestSubmit()
  }
}
