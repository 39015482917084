import { Sessions, SharedNotes, SharedNotesForModule, TrainingSessionStatus } from '../../generated_types/training_plan'
import { assertUnreachable } from '../../utils/asserts'
import { parseLondonDateToUTC, getShortDate, getShortTime } from '../../utils/date_helpers'
import { apiRequest } from '../../utils/json_request'
import { cycleState, stateChanged } from '../states'
import {
  Attendances,
  PlannedSession,
  PlannedSessionData,
  PlannedSessionState,
  TrainingPlan,
  Unavailability,
  Unavailabilities,
  Modules,
  PlanDependencies,
  ModuleAttendances,
  PlannedSessionUserData,
  UnavailabilityType,
  SessionDuration,
  Learner,
  Module,
  TrainingSessionState,
  PlannedSessionStatus,
  ChangeRequestWithReview
} from '../types'
import getDisplaySession from './session_status'

/**
 * Takes a module key of the form 'some_subject/some_session' and returns a readable string
 * Used for modules that don't include a title
 */
export const toFriendlyName = (qualified_module_key: string): string => {
  const deUnderscore = (string: string): string => {
    return string.replaceAll(/_(\w)/g, (_match, letter) => ` ${letter.toUpperCase()}`)
  }
  const capitalize = (string: string): string => {
    return string.replace(/^(\w)/, (_match, letter) => letter.toUpperCase())
  }
  const [curriculum_key, module_key] = qualified_module_key.split('/')

  if (!module_key) return capitalize(deUnderscore(curriculum_key))
  return [capitalize(deUnderscore(curriculum_key)), capitalize(deUnderscore(module_key))].join(': ')
}

export const attendancesFromSession = (plannedSession: PlannedSession, userIdsToRemove: Set<number>) => {
  return Object.entries(plannedSession.attendances)
    .filter(([_userId, value]) => value)
    .filter(([userId, _value]) => !userIdsToRemove.has(parseInt(userId)))
    .map(([userId, { attendance_requirement, do_not_schedule, do_not_schedule_reason, create_change_request }]) => {
      return {
        user_id: parseInt(userId),
        optional: attendance_requirement === PlannedSessionState.Optional,
        _destroy: attendance_requirement === PlannedSessionState.None,
        _create_change_request: create_change_request ? true : undefined,
        do_not_schedule,
        do_not_schedule_reason
      }
    })
}

export const serializePlan = (
  plannedSessions: PlannedSession[],
  learners: Array<Learner>,
  userIdsToRemove: Set<number>,
  learnerPositionsHaveChanged: boolean,
  lockVersion: number
) => {
  const positionsHaveChanged = !!plannedSessions.find(
    (session, index) => session.dbState && session.dbState.index !== index
  )

  return {
    training_plan_users_attributes: learners.map((learner, index) => ({
      id: learner.id,
      user_id: learner.user_id,
      _destroy: userIdsToRemove.has(learner.user_id),
      assessment_id: learner.assessment_id,
      row_order_position: learnerPositionsHaveChanged ? index : undefined
    })),

    planned_sessions_attributes: plannedSessions.map((session, index) => {
      const payload = {
        id: session.id,
        attendance_attributes: attendancesFromSession(session, userIdsToRemove),
        training_module: session.training_module,
        status: session.status,
        _destroy: session.markedForDestruction,
        row_order_position: positionsHaveChanged ? index : undefined
      }
      return payload
    }),
    lock_version: lockVersion
  }
}

export const attendancesChanged = (firstAttendances: Attendances, secondAttendances: Attendances) => {
  // only consider the ones with a status since those are the only ones we care about
  // for the purposes of this check
  const firstAttendanceEntries = Object.entries(firstAttendances).filter(
    ([_id, value]) => value && value.attendance_requirement !== PlannedSessionState.None
  )
  const secondAttendanceEntries = Object.entries(secondAttendances).filter(
    ([_id, value]) => value && value.attendance_requirement !== PlannedSessionState.None
  )
  if (firstAttendanceEntries.length !== secondAttendanceEntries.length) {
    return true
  } else {
    return !!firstAttendanceEntries.find(([id, firstAttendance]) =>
      stateChanged(firstAttendance, secondAttendances[id])
    )
  }
}

export const fetchUserSessionStatuses = async ({
  trainingPlanId,
  userId,
  moduleKeys
}: {
  trainingPlanId: number
  userId: number
  moduleKeys: string[]
}) => {
  const response = await apiRequest(`/plans/${trainingPlanId}/users/${userId}/status`, {
    method: 'GET',
    payload: undefined,
    query: { module_keys: moduleKeys }
  })

  if (response.ok) {
    return response.data
  } else {
    throw `User data could not be loaded: ${response.status}`
  }
}

export const fetchSessionStatuses = async ({
  trainingPlanId,
  userIds,
  module
}: {
  trainingPlanId: number
  userIds: Array<number>
  module: string
}) => {
  const response = await apiRequest(`/plans/${trainingPlanId}/modules/${encodeURIComponent(module)}/status`, {
    method: 'GET',
    payload: undefined,
    query: { user_ids: userIds }
  })

  if (response.ok) {
    return response.data
  } else {
    throw new Error(`Module data could not be loaded: ${response.status}`)
  }
}

export const fromDBState = (sessions: PlannedSessionData[]): PlannedSession[] => {
  return sessions.map((session, index) => {
    return {
      ...session,
      dbState: {
        index,
        ...session
      }
    }
  })
}

/**
 * The beforeunload event is messy - largely due to abuse, this is why we can't have nice things
 * (see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event)
 *
 * Using both preventDefault() and returnValue (though deprecated) here means that in the majority of cases
 * the browser will message the user about leaving the page with unsaved changes.
 * (you cannot call window.alert, etc as it will be ignored)
 */
export const warnOfChanges: EventListener = event => {
  event.preventDefault()
  event.returnValue = false // Historically took a string
}

export const buildRevertibleState = (trainingPlan: TrainingPlan) => {
  return {
    plannedSessions: fromDBState(trainingPlan.planned_sessions),
    learners: trainingPlan.learners
  }
}

/**
 * After adding users from an assessment to an existing plan, clear the query params.
 * If not, then if a user reverted & then reloaded, or saved (but having removed some of the users) & reloaded,
 * have those users-re-added to the plan rather than the refresh reflecting the state in the db.
 */
export const discardQueryParametersFromUrl = () => history.pushState(null, '', window.location.pathname)

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message
  if (typeof error === 'string') return error
  return JSON.stringify(error)
}

/**
 * Takes an array of Unavailability and transmutes it to a Unavailabilities object
 * (indexed by user id)
 */
export const parseUnavailabilities = (unavailabilities: Unavailability[]) => {
  const unavailabilitiesObject: Unavailabilities = {}
  unavailabilities.forEach(unavailability => {
    if (unavailabilitiesObject[unavailability.user_id])
      unavailabilitiesObject[unavailability.user_id].push(unavailability)
    else unavailabilitiesObject[unavailability.user_id] = [unavailability]
  })
  return unavailabilitiesObject
}

interface DeterminePlanDependenciesParams {
  userIds: number[]
  modules: Modules
  plannedSessions: PlannedSession[]
  selectedDuration?: SessionDuration
  sharedNotes: SharedNotes
  sessions: Sessions
}

/**
 * Determines the dependencies (module keys yet to be completed) for every module-user pair within a plan
 */
export const determinePlanDependencies = ({
  userIds,
  modules,
  plannedSessions,
  selectedDuration,
  sharedNotes,
  sessions
}: DeterminePlanDependenciesParams) => {
  const dependencies: PlanDependencies = {}
  userIds.forEach(userId => {
    Object.entries(modules).forEach(([moduleKey, module]) => {
      if (moduleKey && moduleKey) {
        const moduleDependencies = module && module.module_dependencies ? module.module_dependencies : []

        moduleDependencies.forEach(dependentModuleKey => {
          const matchingSession = plannedSessions.find(
            matchSession => matchSession.training_module === dependentModuleKey
          )
          const matchingSessionUserAttendance = matchingSession?.attendances?.[userId]
          const matchingDisplaySession = getDisplaySession(sessions[dependentModuleKey]?.[userId])

          const matchingSessionMandatoryAndIncomplete =
            !!matchingSession &&
            matchingSessionUserAttendance?.attendance_requirement === PlannedSessionState.Mandatory &&
            matchingDisplaySession?.status !== TrainingSessionState.Complete

          const notesSharedWithoutSessionRequirement =
            sharedNotes[dependentModuleKey]?.[userId]?.no_session_needed === true

          let matchingSessionScheduledBefore = false
          // Don't include dependency if it is scheduled for a time after the current search
          if (
            selectedDuration &&
            matchingDisplaySession &&
            [TrainingSessionState.Scheduled, TrainingSessionState.ScheduledAccepted].includes(
              matchingDisplaySession.status
            )
          ) {
            const matchingSessionDate = new Date(matchingDisplaySession.start)
            const selectedDate = parseLondonDateToUTC(selectedDuration.start_date, selectedDuration.start_time)
            if (selectedDate) matchingSessionScheduledBefore = matchingSessionDate.getTime() < selectedDate.getTime()
          }

          if (
            matchingSessionMandatoryAndIncomplete &&
            !matchingSessionScheduledBefore &&
            !notesSharedWithoutSessionRequirement &&
            !matchingSessionUserAttendance?.do_not_schedule
          ) {
            if (!dependencies[moduleKey]) dependencies[moduleKey] = {}
            if (!dependencies[moduleKey][userId]) dependencies[moduleKey][userId] = []
            dependencies[moduleKey][userId].push(dependentModuleKey)
          }
        })
      }
    })
  })
  return dependencies
}

/**
 * Takes an array of PlannedSession and reduces it down to a ModuleAttendances object
 */
export const parseAttendances = (sessions: PlannedSession[]) => {
  const moduleAttendances: ModuleAttendances = {}
  sessions.forEach(session => {
    const moduleKey = session.training_module
    moduleAttendances[moduleKey] = session.attendances
  })
  return moduleAttendances
}

/**
 * Given user session data, is this planned session selectable in the plan
 */
export const isSessionSelectable = (
  plannedSession?: PlannedSessionUserData,
  learnerModuleAccess?: SharedNotesForModule[number],
  sessionsStatuses?: TrainingSessionStatus[]
) =>
  isSessionNeeded(plannedSession, learnerModuleAccess) &&
  isSessionAvailable(sessionsStatuses) &&
  learnerModuleAccess?.no_session_needed !== true

/**
 * Is a session needed, i.e. required or optional & not marked as 'do not schedule'
 */
export const isSessionNeeded = (
  plannedSession?: PlannedSessionUserData,
  learnerModuleAccess?: SharedNotesForModule[number]
) =>
  plannedSession &&
  plannedSession.attendance_requirement !== PlannedSessionState.None &&
  !plannedSession.do_not_schedule &&
  learnerModuleAccess?.no_session_needed !== true

/**
 * Is a session "available", i.e. not completed, scheduled or unconfirmed
 */
export const isSessionAvailable = (sessionsStatuses?: TrainingSessionStatus[]) => {
  const session = getDisplaySession(sessionsStatuses)
  if (!session) return true // No session yet
  switch (session.status) {
    case TrainingSessionState.ScheduledDeclined:
    case TrainingSessionState.Partial:
    case TrainingSessionState.Declined:
    case TrainingSessionState.Absent:
      return true
    case TrainingSessionState.Complete:
    case TrainingSessionState.Unconfirmed:
    case TrainingSessionState.Scheduled:
    case TrainingSessionState.ScheduledAccepted:
      return false
    default:
      assertUnreachable(session.status)
  }
}

/**
 * Given an Unavailability, formats a human readable string
 */
export const formatUnavailabilityText = (unavailability: Unavailability) => {
  const fromDate = new Date(unavailability.from)
  const toDate = unavailability.upto ? new Date(unavailability.upto) : undefined

  switch (unavailability.type) {
    case UnavailabilityType.Session:
      return `In Coaching Session from ${getShortTime(fromDate)}${toDate ? ` until ${getShortTime(toDate)}` : ``}`
    case UnavailabilityType.Snooze:
      return `Snoozed from ${getShortDate(fromDate)}${toDate ? ` until ${getShortDate(toDate)}` : ``}${
        unavailability.description ? ` (${unavailability.description})` : ''
      }`
    case UnavailabilityType.WorkingHours:
      return `Unavailable ${
        toDate ? `${getShortTime(fromDate)} - ${getShortTime(toDate)}` : `after ${getShortTime(fromDate)}`
      } (Outside Working Hours)`
    case UnavailabilityType.CompanyPause:
      return `Company Pause from ${getShortDate(fromDate)}${toDate ? ` until ${getShortDate(toDate)}` : ``}${
        unavailability.description ? `\n(${unavailability.description})` : ''
      }`
    default:
      assertUnreachable(unavailability.type)
  }
}

/**
 * Returns a stub for user entered modules (those with no BE data)
 */
export const generateModuleStub = (moduleKey: string): Module => {
  return {
    title: toFriendlyName(moduleKey),
    exists: false,
    published: false,
    deprecated: false,
    module_dependencies: [],
    estimated_minutes: 60,
    default_session_type: null
  }
}

enum ModuleTooltip {
  DoesNotExist = 'Module does not exist',
  UnPublished = 'Module not published',
  Deprecated = 'Module has been deprecated'
}

/**
 * Build icon and associated tooltip, if module is unpublished or non-existant
 */
export const getModuleIssueContent = (module: Module) => {
  let moduleTooltip: ModuleTooltip | undefined = undefined
  let moduleIcon: string | undefined = undefined
  if (!module || module.exists !== true) {
    moduleTooltip = ModuleTooltip.DoesNotExist
    moduleIcon = '⛔️'
  } else if (module.published !== true) {
    moduleTooltip = ModuleTooltip.UnPublished
    moduleIcon = '⚠️'
  } else if (module.deprecated) {
    moduleTooltip = ModuleTooltip.Deprecated
    moduleIcon = '⛔️'
  }
  return { moduleTooltip, moduleIcon }
}

export const hiddenState = (s: PlannedSessionStatus | undefined): boolean => {
  if (!s) {
    return false
  }
  return s != PlannedSessionStatus.Included
}

/**
 * Returns the un-archived, un-rejected change request (if one exists), favouring the the most recent
 */
export const currentChangeRequest = (changeRequests: ChangeRequestWithReview[]) => {
  const applicableRequests = changeRequests.filter(request => !request.archived && request.response !== 'rejected')

  if (applicableRequests.length === 0) return undefined
  if (applicableRequests.length === 1) return applicableRequests[0]
  return applicableRequests.reduce((request1, request2) =>
    new Date(request1.created_at) > new Date(request2.created_at) ? request1 : request2
  )
}

/**
 * Given a coaching plan cell state in the process of being clicked, and associated change request,
 * determines if the next state in the cycle will conflict with the change request
 */
export const hasChangeRequestConflict = (
  userData?: PlannedSessionUserData,
  rejectedChangeRequestIds?: number[],
  sessionStatuses?: TrainingSessionStatus[]
) => {
  if (!userData || userData.change_requests.length < 1) return false

  const change_request = currentChangeRequest(userData.change_requests)
  // There is no change request or it's already marked as to be rejected
  if (!change_request || rejectedChangeRequestIds?.includes(change_request.id)) return false

  const { attendance_requirement } = cycleState(userData, sessionStatuses)
  // If the cell state can not be changed (i.e. scheduled session) then there's no reason to warn
  if (attendance_requirement === userData.attendance_requirement) return false

  if (
    attendance_requirement === PlannedSessionState.Optional || // If we're changing to optional when a change request exists, then warn
    (change_request.requested_state === 'dont_need' && attendance_requirement === PlannedSessionState.Mandatory) ||
    (change_request.requested_state === 'would_like' && attendance_requirement === PlannedSessionState.None)
  )
    return true

  return false
}

/**
 * Returns a human readable version of a change request original or requested state
 */
export const prettifyRequestState = (state: ChangeRequestWithReview['original_state' | 'requested_state']) => {
  switch (state) {
    case 'not_needed':
      return 'Not Needed'
    case 'would_like':
      return 'Include In Plan'
    case 'dont_need':
      return 'Omit From Plan'
    case 'optional':
      return 'Optional'
    case 'recommended':
      return 'Recommended'
  }
}

/* calculates which users are complete. There are a number of edge cases / considerations:
 * - a user that has no attendances at all is not complete: this avoids an edge case where the add user button appears not to work because the freshly added user has completed all of the 0 sessions they are enabled for
 * - we use the saved state, not the current state. If we use the current state then toggling a cell, hiding a session can make users disappear
 ( - hidden sessions don't count (regardless of showHidden state)
*/

export const calculateCompleteUserIds = (
  userIdsInPlan: number[],
  plannedSessions: PlannedSession[],
  requireOptionalAttendances: boolean,
  sharedNotes: SharedNotes,
  sessions: Sessions
): number[] => {
  return userIdsInPlan.filter(userId =>
    userIsComplete(userId, plannedSessions, sharedNotes, sessions, requireOptionalAttendances)
  )
}

const userIsComplete = (
  userId: number,
  plannedSessions: PlannedSession[],
  sharedNotes: SharedNotes,
  sessions: Sessions,
  requireOptionalAttendances: boolean
): boolean => {
  const attendanceData = plannedSessions
    .filter(ps => ps.dbState?.status === PlannedSessionStatus.Included)
    .map(session => ({
      attendance: session.dbState?.attendances[userId.toString()],
      learnerModuleAccess: sharedNotes[session.training_module]?.[userId],
      sessionsForUser: sessions[session.training_module]?.[userId]
    }))
    .filter(({ attendance }) => attendance !== undefined) // if there are shared notes but
  // no attendance data then filter out the user
  // cells without optional/Mandatory requirements are irrelevant
  if (attendanceData.length == 0) {
    return false
  }

  return attendanceData.every(({ attendance: att, learnerModuleAccess, sessionsForUser }) => {
    if (!att) {
      return true
    } // this can't actually happen - we filter these out above
    if (att.attendance_requirement === PlannedSessionState.None) return true
    if (att.attendance_requirement === PlannedSessionState.Optional && !requireOptionalAttendances) return true
    if (learnerModuleAccess?.no_session_needed === true) return true
    if (att.do_not_schedule) return true
    const session = getDisplaySession(sessionsForUser)
    if (session) {
      return session.status === TrainingSessionState.Complete
    }
    return false
  })
}

//merges any data of the form module_key => user_id => value
//for example shared notes data
type PerModuleAndUserData<T> = Record<string, Record<number, T>>

// the default merge replaces the old data with the new data
// this is useful if the new per user data is a complete replacement, eg we have fetched all the sessions for a user
// However when the data is more partial, eg we have one new session to add to the list of sessions for a user then something more specific is needed

const defaultMerge = <T>(_oldData: T, newData: T) => newData

export const mergePerModuleAndUserData = <T>(
  existing: PerModuleAndUserData<T>,
  updates: PerModuleAndUserData<T>,
  merge?: (oldData: T, newData: T) => T
): PerModuleAndUserData<T> => {
  const result = { ...existing }
  merge ||= defaultMerge

  Object.entries(updates).forEach(([moduleKey, newPerModuleData]) => {
    if (moduleKey in result) {
      // map of userId => T
      const existingModuleData = result[moduleKey]

      const mergedData = Object.fromEntries(
        Object.entries(newPerModuleData).map(([userId, data]) => {
          if (userId in existingModuleData) {
            return [userId, merge(existingModuleData[userId], data)]
          } else {
            // this is a completely new data point
            return [userId, data]
          }
        })
      )

      result[moduleKey] = { ...existingModuleData, ...mergedData }
    } else {
      result[moduleKey] = newPerModuleData
    }
  })
  return result
}

export const deepMergeSessionData = (existing: Sessions, updates: Sessions) => {
  const merge = (old: TrainingSessionStatus[], newUserData: TrainingSessionStatus[]) => {
    const newCodes = newUserData.map(status => status.code)
    const oldWithoutNew = old.filter(status => !newCodes.includes(status.code))

    return oldWithoutNew
      .concat(newUserData)
      .sort((left, right) => new Date(left.start).getTime() - new Date(right.start).getTime())
  }

  return mergePerModuleAndUserData(existing, updates, merge)
}

type CurrentAttendanceParams = {
  moduleKey: string
  userId: number
  plannedSessions: PlannedSessionData[]
}

export const currentAttendanceRequirement = ({ plannedSessions, userId, moduleKey }: CurrentAttendanceParams) => {
  return currentAttendance({ plannedSessions, userId, moduleKey })?.attendance_requirement ?? PlannedSessionState.None
}

export const currentAttendance = ({ plannedSessions, userId, moduleKey }: CurrentAttendanceParams) => {
  const plannedSession = plannedSessions.find(ps => ps.training_module === moduleKey)
  return plannedSession?.attendances[userId]
}

/* look into Sesssions and remove any sessions with the specified code. We use this for updates to ensures that any update is fully taken into account: an update could be removing modules, users or both
The updated session would be merged in, but merging is only ever additive - it wouldn't remove the old session from [module, userid] pairs it no longer applies to
therefore we remove them first */

export const removeSessionsByCode = (sessions: Sessions, code: string) => {
  const updatedSessionEntries = Object.entries(sessions).map(([moduleKey, userIdsToSessions]) => {
    const updatedIdsToSessionsEntries = Object.entries(userIdsToSessions).map(([userId, statuses]) => [
      userId,
      statuses.filter(s => s.code !== code)
    ])

    return [
      moduleKey,
      Object.fromEntries(updatedIdsToSessionsEntries.filter(([_userId, statuses]) => statuses.length > 0))
    ]
  })

  return Object.fromEntries(updatedSessionEntries.filter(([_moduleKey, userData]) => Object.keys(userData).length > 0))
}
