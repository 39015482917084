import { ReactNode, useState } from 'react'
import Rails from '@rails/ujs'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import Modal from './library/modal'
import SWFormGroup from './library/sw/sw_form_group'
import { NewPaymentPageProps, PaymentKind, Company, RevenueType, PaymentPurpose } from './generated_types/payments'
import { formatMoney } from './utils/number_helpers'
import InternalCreditValueWarning from './library/internal_credit_value_warning'

const confirmationModal = (invoice_kind: PaymentKind | '', company: Company) => {
  const message = invoice_kind == PaymentKind.Xero ? 'This will create a draft invoice in Xero for' : 'This will charge'
  return (
    <Modal
      id="confirm"
      title="Confirm Payment"
      body={`${message} ${company.name}`}
      footer={
        <>
          <a href="#" className="sw-btn mr-auto">
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            Close
          </a>
          <button type="submit" className="sw-btn btn-primary">
            Create Payment
            <FontAwesomeIcon icon={['fas', 'cash-register']} />
          </button>
        </>
      }
    />
  )
}

const OneOffPaymentForm = ({ url, company, payment, errors }: NewPaymentPageProps) => {
  const [amount, setAmount] = useState(payment.amount?.toString() || '')
  const [credits, setCredits] = useState(payment.credits?.toString() ?? '')
  const [kind, setKind] = useState(payment.kind || ('' as const))
  const [purpose, setPurpose] = useState(payment.purpose)
  const [revenueType, setRevenueType] = useState(payment.revenue_type || ('' as const))
  const [numberOfWeeks, setNumberOfWeeks] = useState(payment.expected_number_of_weeks || '')
  const [attributionStartDate, setAttributionStartDate] = useState(payment.attribution_start_date || '')
  const [invoiceNote, setInvoiceNote] = useState(payment.invoice_note || '')

  const parsedAmount = parseFloat(amount)
  const parsedCredits = parseInt(credits)
  const costPerCredit =
    !isNaN(parsedAmount) && !isNaN(parsedCredits) && parsedCredits > 0 ? parsedAmount / parsedCredits : null

  const disableFields =
    !(
      (kind === PaymentKind.Stripe && company.stripe_configured) ||
      (kind === PaymentKind.Xero && company.xero_configured)
    ) || company.tax_rate === null

  const stripeStatus =
    kind === PaymentKind.Stripe && !company.stripe_configured ? (
      <span className="text-warning-highlight">No card details have been added to the company</span>
    ) : null

  let xeroStatus: ReactNode
  if (kind === PaymentKind.Xero) {
    if (company.xero_configured) {
      xeroStatus = (
        <a href={company.xero_contact_url} className="sw-btn ml-auto" target="blank">
          <FontAwesomeIcon icon={['fas', 'building-columns']} />
          View Xero Contact
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </a>
      )
    } else {
      xeroStatus = (
        <>
          <span className="text-warning-highlight">No Xero Contact present</span>
          <a href={company.xero_contact_url} className="sw-btn ml-auto">
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={['fas', 'building-columns']} />
              <FontAwesomeIcon icon={['fas', 'circle-plus']} transform="shrink-2 right-9 down-9" />
            </span>
            Create Xero Contact
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </a>
        </>
      )
    }
  }

  let revenueTypeHint: ReactNode

  if (company.pilot) {
    revenueTypeHint = <p className="form-input-hint">This payment will be marked as pilot</p>
  } else if (revenueType === RevenueType.FixedRevenue) {
    revenueTypeHint = (
      <p className="form-input-hint">
        A one-off payment for a fixed amount of coaching, that we do not expect to continue after it is complete
      </p>
    )
  } else if (revenueType === RevenueType.Recurring) {
    revenueTypeHint = (
      <p className="form-input-hint">
        A payment which we expect to be repeated, or turn into equivalent recurring revenue, rather than for an agreed
        set of modules
      </p>
    )
  }

  return (
    <form action={url} method="POST" className="flex flex-col gap-4">
      <input type="hidden" value={Rails.csrfToken() ?? undefined} name={Rails.csrfParam() ?? undefined} />

      {company.tax_rate && <div className="sw-toast">{company.tax_rate_description}</div>}
      <SWFormGroup errors={errors['kind']}>
        <label htmlFor="payment_kind" className="sw-label">
          Kind
        </label>
        <select
          id="payment_kind"
          className="sw-select"
          name="payment[kind]"
          value={kind}
          onChange={e => setKind(e.target.value as PaymentKind)}
        >
          <option />
          <option value={PaymentKind.Stripe}>Credit Card (Stripe)</option>
          <option value={PaymentKind.Xero}>Invoice (Xero)</option>
        </select>
      </SWFormGroup>
      <div className="flex items-center">
        {stripeStatus}
        {xeroStatus}
      </div>

      <SWFormGroup errors={errors['amount']}>
        <label htmlFor="payment_amount" className="sw-label">
          Amount ({payment.currency})
        </label>
        <input
          id="payment_amount"
          className="sw-input"
          type="number"
          name="payment[amount]"
          min="0"
          step="any"
          value={amount}
          disabled={disableFields}
          onChange={e => setAmount(e.target.value)}
        />
      </SWFormGroup>

      <SWFormGroup errors={errors['credits']}>
        <label htmlFor="payment_credits" className="sw-label">
          Credits
          {costPerCredit && (
            <span className="text-oceanblue">({formatMoney(costPerCredit, payment.currency)} per credit)</span>
          )}
        </label>
        <input
          // class disabled rather than actually disabling the input
          // because disabled inputs don't submit their value
          id="payment_credits"
          className={`sw-input ${purpose !== PaymentPurpose.Credits ? 'disabled' : ''}`}
          type="number"
          name="payment[credits]"
          min="0"
          value={credits}
          disabled={disableFields}
          readOnly={purpose !== PaymentPurpose.Credits}
          onChange={e => setCredits(e.target.value)}
        />
        <InternalCreditValueWarning />
      </SWFormGroup>

      <SWFormGroup errors={errors['invoice_note']}>
        <label htmlFor="payment_invoice_note" className="sw-label">
          Invoice Note
        </label>
        <input
          id="payment_invoice_note"
          className={`sw-input`}
          name="payment[invoice_note]"
          min="0"
          value={invoiceNote}
          onChange={e => setInvoiceNote(e.target.value)}
        />
        <p className="form-input-hint">Invoice notes are displayed on the invoice</p>
      </SWFormGroup>

      <SWFormGroup errors={errors['expected_number_of_weeks']}>
        <label htmlFor="payment_expected_number_of_weeks" className="sw-label">
          Expected Number of Weeks Covered
        </label>
        <input
          id="payment_expected_number_of_weeks"
          className="sw-input"
          type="number"
          name="payment[expected_number_of_weeks]"
          min="0"
          value={numberOfWeeks}
          disabled={disableFields}
          onChange={e => setNumberOfWeeks(e.target.value)}
        />
        <p className="form-input-hint">
          {' '}
          {purpose == PaymentPurpose.Credits
            ? 'The number of weeks of training the credits are expected to cover'
            : 'The number of weeks the payment should apply to'}
        </p>
      </SWFormGroup>

      <SWFormGroup errors={errors['attribution_start_date']}>
        <label htmlFor="payment_attribution_start_date" className="sw-label">
          Attribution Start Date
        </label>
        <input
          id="payment_attribution_start_date"
          className="sw-input"
          type="date"
          name="payment[attribution_start_date]"
          value={attributionStartDate}
          disabled={disableFields}
          onChange={e => setAttributionStartDate(e.target.value)}
        />
        <p className="form-input-hint">
          When we want to start attributing this revenue, if companies have paid in advance
        </p>
      </SWFormGroup>

      <SWFormGroup errors={errors['revenue_type']}>
        <label htmlFor="payment_revenue_type" className="sw-label">
          Revenue Type
        </label>
        <select
          id="payment_revenue_type"
          className="sw-select"
          name="payment[revenue_type]"
          value={revenueType}
          disabled={disableFields || company.pilot}
          onChange={e => setRevenueType(e.target.value as RevenueType)}
        >
          <option />
          <option value={RevenueType.FixedRevenue}>Fixed</option>
          <option value={RevenueType.Recurring}>Recurring</option>
        </select>
        {revenueTypeHint}
        <p className="form-input-hint">
          Select &quot;Fixed&quot; if this payment will be used towards a fixed scope project.
        </p>
        <p className="form-input-hint">
          Select &quot;Recurring&quot; if this payment is expected to repeat, subscription-style, with no specific end
          point in mind.
        </p>
      </SWFormGroup>

      <SWFormGroup errors={errors['purpose']}>
        <label htmlFor="payment_puprose" className="sw-label">
          Purpose
        </label>
        <select
          id="payment_purpose"
          className="sw-select"
          name="payment[purpose]"
          value={purpose}
          disabled={disableFields}
          onChange={e => {
            setPurpose(e.target.value as PaymentPurpose)
            if (e.target.value !== PaymentPurpose.Credits) {
              setCredits('0')
            }
          }}
        >
          <option value={PaymentPurpose.Credits}>Credits</option>
          <option value={PaymentPurpose.CurriculumCreation}>Curriculum Creation</option>
        </select>
      </SWFormGroup>

      <a href="#confirm" className={`sw-btn btn-primary ml-auto${disableFields ? ' disabled' : ''}`}>
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={['fas', 'cash-register']} />
          <FontAwesomeIcon icon={['fas', 'circle-plus']} transform="shrink-2 right-11 down-7" />
        </span>
        Create
      </a>
      {confirmationModal(kind, company)}
    </form>
  )
}

export default OneOffPaymentForm
