import { Formats, Views } from '@skiller-whale/react-big-calendar'
import { CoachTimetableEventData } from '../generated_types/coach_availability'
import { CalendarEvent } from './types'

export const eventClassNames = (event: CalendarEvent, editable: boolean) => {
  let className = ''

  switch (event.type) {
    case 'training_session':
      className = 'bg-coralorange'
      break
    case 'coach_availability':
      if (!editable) {
        className = 'opacity-40'
      }
      break
  }

  if (event.loading) {
    className += ' sw-loading loading-lg animate-pulse'
  }

  return { className }
}

export const formatEvent = (event: CoachTimetableEventData, showNames: boolean): CalendarEvent => {
  return {
    ...event,
    isAllDay: event.type === 'snooze',
    start: new Date(event.start),
    end: new Date(event.end),
    userId: event.user_id,
    userName: event.user_name,
    title: showNames ? [event.title, event.user_name].filter(x => x).join('\n') : event.title
  }
}

export const ENABLED_VIEWS = [Views.WORK_WEEK]
export const FORMATS: Formats = {
  // see https://github.com/moment/luxon/blob/master/docs/formatting.md
  dayFormat: 'cccc',
  timeGutterFormat: 'HH:mm',
  eventTimeRangeFormat: (range, culture, localizer) => {
    if (!localizer) return ''
    return `${localizer.format(range.start, 'HH:mm')} - ${localizer.format(range.end, 'HH:mm')}`
  }
}
