import { Controller } from '@hotwired/stimulus'
import { SELECT_COACH_EVENT } from '../components/library/trainer_selector'

export default class extends Controller<HTMLElement> {
  static targets = ['button']

  declare buttonTargets: HTMLButtonElement[]

  connect() {
    if (!window.opener) {
      this.buttonTargets.forEach(btn => {
        btn.disabled = true
      })
    }
  }

  select(e: MouseEvent) {
    const currentTarget = e.currentTarget
    if (!(currentTarget && currentTarget instanceof HTMLElement)) return
    if (!currentTarget.dataset['coachId']) return

    const coachId = parseInt(currentTarget.dataset['coachId'])

    if (!window.opener) {
      alert('Current window has no opener')
      return
    }

    window.opener.postMessage({ event: SELECT_COACH_EVENT, coachId }, window.origin)
    window.close()
    window.opener.focus()
  }
}
