import { Root, createRoot } from 'react-dom/client'
import { Controller } from '@hotwired/stimulus'
import CoachAvailabilityViewer from '../components/coach_availability_editor/coach_availability_viewer'
import {
  ClearCoachSelectionEvent,
  CoachSelectionEvent,
  CoachSelectionEventData
} from '../components/coach_availability_editor/types'
import { Timezone } from '../components/generated_types/coach_availability'

export default class CoachAvailabilityController extends Controller<HTMLElement> {
  root: Root | undefined
  checkedUserIds: Set<number>
  static targets = ['container', 'coachInput']
  static values = {
    date: String,
    timezone: Object
  }

  declare containerTarget: HTMLElement
  declare coachInputTargets: HTMLElement[]
  declare dateValue: string
  declare timezoneValue: Timezone

  connect() {
    this.root = createRoot(this.containerTarget)
    this.root.render(
      <CoachAvailabilityViewer container={this.containerTarget} date={this.dateValue} timezone={this.timezoneValue} />
    )
    this.checkedUserIds = new Set()
  }

  disconnect() {
    this.unmount()
    this.checkedUserIds.clear()
  }

  unmount() {
    if (this.root) {
      this.root.unmount()
      this.root = undefined
    }
  }

  toggleUser(event: InputEvent) {
    if (!event.currentTarget) return
    const checkbox = event.currentTarget as HTMLInputElement
    const userId = parseInt(checkbox.value)

    if (checkbox.checked) {
      this.checkedUserIds.add(userId)
    } else {
      this.checkedUserIds.delete(userId)
    }

    this.containerTarget.dispatchEvent(
      new CustomEvent<CoachSelectionEventData>(CoachSelectionEvent, { detail: { userId, checked: checkbox.checked } })
    )
  }

  coachInputTargetConnected(element) {
    if (!this.checkedUserIds) {
      //when the component is being connected initially we don't want to do anything
      return
    }
    if (element instanceof HTMLInputElement) {
      const userId = parseInt(element.value)
      if (this.checkedUserIds.has(userId)) {
        element.checked = true
      }
    }
  }

  clearSelection() {
    this.checkedUserIds = new Set()
    this.containerTarget.dispatchEvent(new CustomEvent(ClearCoachSelectionEvent))
  }
}
